import React, { useCallback, useEffect, useState } from 'react';
import './Dashboard.scss';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import { useStore } from '../../store/hooks';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import { useCheckSingleSapPermission } from '../../security/security-hook';
import {
  PERMISSION_ALLOW,
  PERMISSION_VIEW,
  RESOURCE_DASHBOARD_BUTTON_SCHEDULE_NOW, RESOURCE_DASHBOARD_BYPASS_ACKNOWLEDGEMENTS,
  RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT,
} from '../../types/users-types';

const Dashboard = observer(() => {
  const { dashboardStore: store, apiStore, globalStore } = useStore();
  const { sapIds, loaders, buttonsDisabled } = store;
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const allowUserManagement = useCheckSingleSapPermission(sapIds, RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT, PERMISSION_VIEW);
  const allowScheduleNow = useCheckSingleSapPermission(sapIds, RESOURCE_DASHBOARD_BUTTON_SCHEDULE_NOW, PERMISSION_ALLOW);
  const bypassAcknowledgements = useCheckSingleSapPermission(sapIds, RESOURCE_DASHBOARD_BYPASS_ACKNOWLEDGEMENTS, PERMISSION_ALLOW);

  useEffect(() => {
    if (apiStore.isAuthenticated) {
      void store.init();
    }
    return () => store.close();
  }, [store, apiStore.isAuthenticated]);

  useEffect(() => {
    setSelectedIndex(globalStore.userSaps.findIndex(us => us.id === globalStore.selectedSap.id));
  }, [globalStore.selectedSap.id, globalStore.userSaps]);

  const focusRef = useCallback((el: HTMLDivElement | null, index) => {
    if (index === selectedIndex) el?.scrollIntoView({ block: 'center' });
  }, [selectedIndex]);

  if (loaders.page) return <Spinner text="One moment while we retrieve your affiliated practices"/>;
  return (
    <div className='dashboard-wrapper'>
      {globalStore.userSaps.length > 0 && (
        <div className="page-heading">
          <div className="heading--level-1">Welcome to MyCornerstone Self Service Portal</div>
          <div className="spot-typography__text--tertiary">
            Here you can: <br/>
            <ul>
              <li>Set up and manage approved integrations with your Cornerstone software</li>
              <li>Visit the Cornerstone Online Knowledgebase</li>
              <li>View practices and manage practice users and their permissions with your MyIDEXX account</li>
              <li>Check your system settings and schedule your automatic Cornerstone upgrade</li>
            </ul>
          </div>
        </div>
      )}
      {globalStore.userSaps?.map((sap, index) => (
        <div
          className='business'
          key={sap.id}
          ref={el => focusRef(el, index)}
        >
          <ContentHeader className='expand-toggle' onClick={() => globalStore.changeSelectedSap(sap.id)} cypressData={`expandable-${sap.id}`}>
            {globalStore.selectedSap.id === sap.id
              ? <SpotIcon className="caret-icon" path="caret-up" title="collapse" />
              : <SpotIcon className="caret-icon" path="caret-down" title="expand" />
            }
            &nbsp;{sap.name} ({sap.id})
          </ContentHeader>
          <div
            className={`business-cards ${globalStore.selectedSap.id !== sap.id ? 'collapsed' : ''}`}
          >
            {sap.integrations?.vello && (
              <Card className="business-card" bodyAlign="top-center" >
                <img className="card-icon" src="/images/vello_icon.svg" />
                <div className="card-body-title">
                  Client Engagement Platform
                </div>
                <Button
                  type="primary"
                  size="small"
                  ariaLabel="vello-launch"
                  loading={loaders.vello}
                  onClick={() => store.launchVelloAuthPage(index)}
                  cypressData={`vello-${sap.id}-btn`}
                >
                  Launch
                </Button>
              </Card>
            )}
            <Card className="business-card" bodyAlign="top-center" >
              <img className="card-icon" src="/images/self_help_icon.svg" />
              <div className="card-body-title">
                Cornerstone Help Hub
              </div>
              <Button
                type="primary"
                size="small"
                ariaLabel="helphub-explore"
                onClick={() => window.open('https://cornerstonehelphub.com/', '_blank', 'noopener,noreferrer')}
                cypressData={`explore-${sap.id}-btn`}
              >
                Explore
              </Button>
            </Card>
            <Card className="business-card" bodyAlign="top-center" >
              <img className="card-icon" src="/images/user_management_icon.svg" />
              <div className="card-body-title">
                User Management
              </div>
              <Button
                type="primary"
                size="small"
                disabled={buttonsDisabled}
                loading={loaders.userManagement}
                onClick={() => store.checkAllowUserManagement(index, allowUserManagement[sap.id])}
                cypressData={`user-mgmt-${sap.id}-btn`}
              >
                Manage Users
              </Button>
            </Card>
            <Card className="business-card" bodyAlign="top-center" >
              <img className="card-icon" src="/images/schedule_now.png" />
              <div className="card-body-title">
                Upgrade at your convenience
              </div>
              <Button
                type="primary"
                size="small"
                disabled={buttonsDisabled}
                loading={loaders.scheduleNow}
                onClick={() => store.checkAllowStartScheduleUpgrade(index, allowScheduleNow[sap.id], bypassAcknowledgements[sap.id])}
                cypressData={`schedule-now-${sap.id}-btn`}
              >
                Schedule Now
              </Button>
            </Card>
            <Card className="business-card" bodyAlign="top-center" >
              <img className="card-icon" src="/images/trupanion.png" />
              <div className="card-body-title">
                Trupanion Integration
              </div>
              <Button
                type="primary"
                size="small"
                disabled={buttonsDisabled}
                onClick={() => store.redirectToTrupanion(index)}
                cypressData={`trupanion-${sap.id}-btn`}
              >
                Configure
              </Button>
            </Card>
          </div>
        </div>
      ))}
    </div>
  );
});

export default Dashboard;
