import AbstractStore from './AbstractStore';

export default abstract class AbstractUpgradeTaskStore extends AbstractStore {
  public init(): void {
    this.root.globalStore.setUpgradeTaskSessionEndTimeouts();
  }

  public close(): void {
    this.root.globalStore.clearUpgradeTaskSessionEndTimeouts();
  }
}
