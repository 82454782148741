import { useContext, useEffect } from 'react';
import { MobXProviderContext } from 'mobx-react';
import RootStore from './RootStore';
import AbstractStore from './AbstractStore';
import { PickByType } from '../types/common-types';
import type { History, Blocker, Transition } from 'history';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export const useStore = (): PickByType<RootStore, AbstractStore> => {
  return useContext(MobXProviderContext) as PickByType<RootStore, AbstractStore>;
};

// workaround for React Router v6 which has no such hook
export function useBlocker(blocker: Blocker): void {
  const history = useContext(UNSAFE_NavigationContext).navigator as History;
  useEffect(() => {
    let unblock: () => void = () => {};
    const beforeUnloadListener = (): void => unblock();
    // see https://github.com/remix-run/history/issues/921#issuecomment-1029055351
    window.addEventListener('beforeunload', beforeUnloadListener);
    unblock = history.block((tx: Transition) => {
      blocker({
        ...tx,
        retry: () => {
          unblock();
          tx.retry();
        },
      });
    });
    return () => {
      unblock();
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [history, blocker]);
}
