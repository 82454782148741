import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';
import './RoleManagement.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

const RoleManagement = observer(() => {
  const { roleManagementStore: store } = useStore();
  const { resourcePermissionsFiltered, sorting, loaders, edited, roleName } = store;
  const navigate = useNavigate();

  useEffect(() => {
    void store.init();
    return () => {
      store.close();
    };
  }, [store]);

  if (loaders.page) return <Spinner />;

  return (
    <div className='role-management-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'>Role</div>
      </div>
      <div className="spot-card--primary role-management-card">
        <div className="spot-card--body demo-card-height">
          <div className="spot-typography__text--body">{roleName} {loaders.role && <Spinner />}</div>
        </div>
      </div>
      <div className='role-management-table'>
        <Grid
          id='role-management-grid'
          columns={store.colDefs}
          data={resourcePermissionsFiltered}
          sorting={sorting}
          noRowsText='No data'
          rowIdField='resourceId'
        />
      </div>
      <div className="role-management_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' disabled={!edited || loaders.role} onClick={() => store.updateRole()}>Save</Button>
        </div>
      </div>
    </div>
  );
});

export default RoleManagement;
