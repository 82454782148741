import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';

export type SpotIconPath = 'add' | 'add-a-user' | 'alert-notification' | 'animal-canine' | 'animal-equine' | 'animal-feline' | 'animal-other'
  | 'app-menu' | 'back' | 'backward' | 'beginning' | 'bell' | 'blocked' | 'bookmark' | 'brightness' | 'calendar' | 'camera' | 'cancel' | 'caret-down'
  | 'caret-up' | 'cart' | 'checkbox-indeterminate' | 'checkmark' | 'clipboard' | 'clipboard-medical-notes' | 'clipboard-patient'
  | 'clipboard_medical_notes' | 'clipboard_patient' | 'close' | 'collapse' | 'communicate' | 'contrast' | 'contribute' | 'controls' | 'copy' | 'crop'
  | 'cut' | 'dashboard' | 'delete' | 'doctor' | 'document' | 'double-left' | 'double-right' | 'download' | 'drawer-close' | 'drawer-open' | 'ecg'
  | 'edit' | 'email' | 'end' | 'euro' | 'expand' | 'export' | 'favorite-featured' | 'file-excel' | 'file-image' | 'file-pdf' | 'file-txt'
  | 'file-word' | 'file-zip' | 'filter' | 'fit-window' | 'flag' | 'folder-history' | 'forward' | 'fullscreen' | 'heart' | 'help' | 'help-2' | 'hide'
  | 'home' | 'image' | 'import' | 'in-progress' | 'inbox' | 'info' | 'info-2' | 'inverse' | 'link' | 'list' | 'lock-private' | 'measure' | 'medicine'
  | 'menu' | 'minus' | 'mobile' | 'money' | 'moon' | 'more' | 'more-2' | 'next' | 'open-new-window' | 'owner' | 'paperclip' | 'patient' | 'patient-list'
  | 'patient_list' | 'pause' | 'phone-contact' | 'pill' | 'pin' | 'pin-2' | 'play' | 'plus' | 'power' | 'previous' | 'price' | 'print' | 'reassign'
  | 'recently-searched' | 'redo' | 'refresh-redo' | 'reply' | 'reply-all' | 'reports' | 'request' | 'rotate-image-left' | 'rotate-image-right'
  | 'rotate_image_left' | 'rotate_image_right' | 'save' | 'search' | 'send' | 'settings' | 'share' | 'show' | 'spinner' | 'stop' | 'sun' | 'test-lab'
  | 'time-clock' | 'tools' | 'undo' | 'unlock' | 'upload' | 'user-info' | 'users' | 'video' | 'workstation' | 'zoom-in' | 'zoom-out';

interface SpotSvgProps extends CustomElementProps {
  path: SpotIconPath;
  title?: string;
  onClick?: () => void;
  cypressData?: string;
}

const SpotIcon = (props: SpotSvgProps) => {
  const { path, title, onClick, className, id, ariaLabel, cypressData } = props;

  return (
    <svg
      id={id}
      className={`spot-icon ${className || ''}`}
      role="spot-icon"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabel ? undefined : 'title'}
      onClick={() => onClick && onClick()}
      data-cy={cypressData}
    >
      <title>{title || path}</title>
      <use href={`/images/spot/icons/spot_icons.svg#${path}`}/>
    </svg>
  );
};

export default SpotIcon;
