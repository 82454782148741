class StorageService {
  private static getStorage(permanent: boolean): Storage {
    return permanent ? localStorage : sessionStorage;
  }

  public update(name: string, data: any, permanent = true): void {
    const storage = StorageService.getStorage(permanent);
    const storageDataString = storage.getItem(name);
    const storageData = storageDataString !== null ? JSON.parse(storageDataString) : [];
    const updatedData = Array.isArray(data) ? [...storageData, ...data] : { ...storageData, ...data };
    storage.setItem(name, JSON.stringify(updatedData));
  }

  public replace(name: string, data: any, permanent = true): void {
    const storage = StorageService.getStorage(permanent);
    storage.setItem(name, JSON.stringify(data));
  }

  public getItem(name: string, permanent = true, defaultValue = null): any {
    const storage = StorageService.getStorage(permanent);
    const storageString = storage.getItem(name);
    try {
      return storageString ? JSON.parse(storageString) : defaultValue;
    } catch {
      // ignore
    }
    return null;
  }

  public getProperty(name: string, property: string, permanent = true, defaultValue: any = null): any {
    const storage = StorageService.getStorage(permanent);
    const storageString = storage.getItem(name);
    const storageData = storageString ? JSON.parse(storageString) : {};
    return storageData[property] === undefined ? defaultValue : storageData[property];
  }

  public removeItem(name: string, permanent = true): void {
    StorageService.getStorage(permanent).removeItem(name);
  }
}

const storageService = new StorageService();
export { storageService, StorageService };
