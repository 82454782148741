export type NonNullish = string | number | boolean | object;

export const isDefined = (obj: any): obj is NonNullish => obj !== null && obj !== undefined;

export const isEmpty = (obj: Record<any, any>): boolean => Object.keys(obj).length === 0;

export const sortByField = <T>(array: T[], field: keyof T): T[] => {
  const wrappedArray = array.map(a => ({
    item: a,
    sortValue: String(isDefined(a[field]) ? a[field] : '').toLowerCase(),
  }));
  wrappedArray.sort(((a, b) => a.sortValue > b.sortValue ? 1 : -1));
  return wrappedArray.map(w => w.item);
};

export const fieldValuesCounts = <T = Record<string, any>>(array: T[], field: keyof T): Record<string, number> => {
  const map: Record<string, number> = {};
  array.forEach(item => {
    const value = item[field];
    if (!(typeof value === 'string')) return;
    if (!map[value]) map[value] = 0;
    map[value]++;
  });
  return map;
};

const MEGABYTE = 1024 * 1024;

export const formatMBytes = (mb: number | undefined): string | undefined => {
  if (mb === undefined) return undefined;
  if (mb < 1024) return `${mb}MB`;
  return mb < MEGABYTE ? `${Math.round(mb / 1024)}GB` : `${Math.round(mb / MEGABYTE)}TB`;
};

export const sleep = (ms: number): Promise<void> => new Promise<void>(resolve => setTimeout(resolve, ms));

export const isEmailAddress = (str: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);

export const isIdexxEmailAddress = (str: string): boolean =>
  isEmailAddress(str) && str.toLowerCase().endsWith('@idexx.com');

export const copyToClipboard = (text: string): any => navigator.clipboard.writeText(text);

export const arraysHaveSameElements = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();
  return sorted1.every((e, i) => e === sorted2[i]);
};

export const arrayToMap = <T>(array: T[], field: keyof T): Record<any, T> => {
  const result: Record<any, T> = {};
  array.forEach(item => result[item[field]] = item as any);
  return result;
};


