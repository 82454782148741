import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import Checkbox from '../../components/Checkbox/Checkbox';
import Button from '../../components/Button/Button';
import Grid from '../../components/Grid/Grid';
import { SubRowRenderer } from './renderers';
import { useStore } from '../../store/hooks';
import './CheckQualifications.scss';
import SearchBar from '../../components/SearchBar/SearchBar';
import { runInAction } from 'mobx';
import Pill from '../../components/Pill/Pill';
import SpotIcon from '../../components/SpotIcon/SpotIcon';

const CheckQualifications = observer(() => {
  const { checkQualificationsStore: store, globalStore, apiStore } = useStore();
  const { loaders, sapId, agentsDataFiltered, expandedRowIds, sorting, checkedRowsCount, upgradeCount, noUpgradeCount, auditLoading,
    upgradeButtonEnabled, searchText, hideDoNotUpgrade, findComputersPage, showInstallationInstructions } = store;
  const navigate = useNavigate();

  useEffect(() => {
    if (!sapId) {
      navigate('/');
    } else {
      void store.init();
    }
    return () => store.close();
  }, [navigate, sapId, store]);

  const subrow = {
    renderer: SubRowRenderer,
    actions: {},
  };

  if (loaders.page) return <Spinner text="One moment while we retrieve your computer information"/>;

  if (findComputersPage) {
    return (
      <div className="check-qualifications-wrapper">
        <div className="page-heading skeleton">
          <div className="heading--level-1">Find my Computers for <span title={globalStore.selectedSap.name}>{globalStore.selectedSap.id}</span></div>
          <div className="spot-typography__text--tertiary">
            Welcome to My Cornerstone Self-Service Upgrade!<br />
            To populate the list of computers please click &quot;Find my computers&quot;
          </div>
        </div>
        <Button
          className="find-computers-btn"
          type="primary"
          icon="workstation"
          disabled={globalStore.isLoading}
          loading={globalStore.isLoading}
          onClick={() => store.findMyComputers()}
          cypressData="find-computers-btn"
        >
          Find my Computers
        </Button>
        {showInstallationInstructions && (
          <div className="instructions">
            <p>MyCornerstone uses an application installed alongside Cornerstone to assess compatibility with the latest version, and to install the upgrade on your computers.</p>
            <p><span className="bold">Thin Client workstations do not need this application since they access Cornerstone via Remote Desktop.</span></p>
            <p>
              Click
              <a href="#" aria-label="kaseya-agent-download" onClick={() => store.openKaseyaURL()}>
                here
              </a>
              to download this application and follow the instructions below to complete installation.
            </p>
            <p>
              You can also click
              <a
                href="#"
                aria-label="send-instructions"
                className={`send-instructions ${loaders.sendInstructions ? 'disabled' : ''}`}
                onClick={() => store.sendInstallationInstructions()}
              >
                here
              </a>
              and MyCornerstone will email <span className="bold">{apiStore.userEmail}</span> the download link and instructions.
            </p>
            <h3>Installation Instructions</h3>
            <ol>
              <li>Use the link on the page above, or in the email to download the application.</li>
              <li>Locate the downloaded file on your computer and double click to begin the installation.</li>
              <li className="no-index">
                <span className="bold">Time Saving Tip:</span> Once downloaded, you can copy the installation file to a flash drive and take that drive to each computer
                rather than downloading the file each time.
              </li>
              <li>If prompted, click <span className="bold">Yes</span> on the User Account Control popup</li>
              <li className="no-index">
                <span className="bold">Note:</span> During the installation, an Agent Installation progress bar will display along with several other windows.
              </li>
              <li>After the install completes, click <span className="bold">OK</span> on the Agent Setup message.</li>
              <li className="no-index">
                <span className="bold">Note:</span> This message only remains on screen for a few seconds and may automatically close.
              </li>
              <li>Repeat these steps on each computer your practice uses for Cornerstone.</li>
            </ol>
            <p>Once all computers have the application installed, click the <span className="bold">Find my Computers</span> button above to search for them.</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='check-qualifications-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'> Check Qualifications for <span title={globalStore.selectedSap.name}>{globalStore.selectedSap.id}</span></div>
        <div className='spot-typography__text--tertiary'>
          View your Cornerstone server, workstation connections, and upgrade qualifications. Click <span className='bold'>Need Help</span> for more information.
        </div>
      </div>
      <div className='card-upgrade-status-wrapper skeleton'>
        <div className='spot-card--primary'>
          <div className='spot-typography__text--secondary spot-typography__font-weight--bold align--center-center'>STATUS</div>
          <div className='spot-typography__text--body align--center-center'>
            <div className='display-status-value-wrapper'>
              <div className='total-count'>{noUpgradeCount}</div>
              <Pill type='secondary' small>Do Not Upgrade</Pill>
            </div>
            <div className='display-status-value-wrapper'>
              <div className='total-count'>{upgradeCount}</div>
              <Pill type='positive' small>Upgrade</Pill>
            </div>
          </div>
        </div>
      </div>
      <div className='check-qualifications-note'>
        <div className='note-icon-wrapper'>
          <SpotIcon className="note-icon" path="alert-notification" title="Note" />
        </div>
        <span className='note-text'>
          Not seeing all your computers? Click <a href="#" aria-label="to-find-computers" onClick={() => store.switchToInstallationInstructions()}>here</a> to access
          the Find my Computers screen for instructions.
        </span>
      </div>
      {store.isProcessingRow && (
        <div className='check-qualifications-note'>
          <div className='note-icon-wrapper'>
            <SpotIcon className="note-icon" path="alert-notification" title="Note" />
          </div>
            <span className='note-text'>
              Qualification checks can take up to an hour. You can keep working while they run, or come back later.
            </span>
        </div>
      )}
      <div className='check-qualifications_actions'>
        <div>
          <div className="search-bar-wrapper">
            <SearchBar
              className='skeleton'
              ariaLabel='label-search'
              disabled={globalStore.isLoading}
              maxLength={15}
              initialValue={searchText}
              placeholder='Search Nickname'
              onSearch={v => runInAction(() => store.searchText = v)}
              cypressData="search-agents"
            />
          </div>
          <Checkbox
            id='hide-do-not-upgrade'
            ariaLabel='hide-do-not-upgrade'
            className='hide-do-not-upgrade skeleton'
            disabled={globalStore.isLoading}
            checked={hideDoNotUpgrade}
            onChange={c => runInAction(() => store.hideDoNotUpgrade = c)}
            cypressData="hide-not-upgrade-cb"
          >
            Hide Do Not Upgrade
          </Checkbox>
        </div>
        <div>
          <div className='get-help-link'>
            <Button className='spot-button spot-button--link spot-button--small' disabled={globalStore.isLoading} onClick={() => store.showNeedHelpModal()} cypressData="help-btn">Need Help ?</Button>
          </div>
          <Button className='bold refresh-list-btn skeleton' type='primary' size='small' onClick={() => store.fetchAgents()} disabled={globalStore.isLoading} cypressData="refresh-btn">Refresh List</Button>
        </div>
      </div>
      <div className='check-qualifications-table'>
        <Grid
          id='check-qualifications-grid'
          loading={globalStore.isLoading ? <Spinner text="One moment while we retrieve your computer information"/> : false}
          disabled={globalStore.isLoading}
          columns={store.colDefs}
          data={agentsDataFiltered}
          subrow={subrow}
          expandedRowIds={expandedRowIds}
          sorting={sorting}
          noRowsText={searchText ? `No result were found for "${searchText}"` : 'No data'}
          rowIdField='agentId'
          rowLoadingField='rowLoading'
        />
      </div>
      <div className='check-qualifications_bottom-actions'>
        <div>
          <Button className="cancel-editing" type='link' size='small' onClick={() => globalStore.showExitUpgradePopup(() => store.exitUpgrade())} cypressData="exit-btn">
            Exit Upgrade
          </Button>
          <Button className="cancel-editing" type='link' size='small' onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
        </div>
        <div>
          <Button
            className='initiate-check-btn skeleton'
            type='secondary'
            disabled={globalStore.isLoading || auditLoading || checkedRowsCount === 0}
            onClick={() => store.initiateCheck()}
            cypressData="initiate-audit-btn"
          >
            Initiate Check{checkedRowsCount > 0 && ` - ${checkedRowsCount}`}
          </Button>
          <Button
            className='skeleton'
            type='primary'
            onClick={() => store.scheduleUpgrade()}
            disabled={globalStore.isLoading || !upgradeButtonEnabled || loaders.confirm}
            loading={loaders.confirm}
            cypressData="schedule-date-time-btn"
          >
            Schedule Date &amp; Time
          </Button>
        </div>
      </div>
    </div>
  );
});

export default CheckQualifications;
