import dayjs from 'dayjs';
import { roundTimestamp } from './date-utils';

export interface EventInterval {
  start: number;
  end: number;
}

const flattenEventIntervals = (intervals: EventInterval[]): EventInterval[] => {
  const result: EventInterval[] = [];
  if (intervals.length > 0) {
    const sorted = intervals.sort((a, b) => a.start - b.start);
    let current = sorted[0];
    for (let i = 1; i < sorted.length; i++) {
      const next = sorted[i];
      if (Math.sign(current.start - next.end) * Math.sign(next.start - current.end) >= 0) { // intervals intersect
        current = { start: Math.min(current.start, next.start), end: Math.max(current.end, next.end) };
      } else {
        result.push(current);
        current = next;
      }
    }
    result.push(current);
  }
  return result;
};

export default class EventIntervalsHandler {
  private readonly baseIntervals: EventInterval[];

  private readonly timeIntervalsMinutes: number;

  private intervals: EventInterval[];

  private allowedIntervalSet = false;

  constructor(intervals: EventInterval[], timeIntervalsMinutes: number) {
    this.baseIntervals = flattenEventIntervals(intervals);
    this.intervals = this.baseIntervals;
    this.timeIntervalsMinutes = timeIntervalsMinutes;
  }

  public setAllowedInterval(allowedInterval: EventInterval): void {
    this.allowedIntervalSet = true;
    this.intervals = flattenEventIntervals([
      ...this.baseIntervals,
      {
        start: 0,
        end: dayjs(roundTimestamp(allowedInterval.start, this.timeIntervalsMinutes, false)).add(-this.timeIntervalsMinutes, 'minutes').valueOf()
      },
      {
        start: dayjs(roundTimestamp(allowedInterval.end, this.timeIntervalsMinutes, true)).add(this.timeIntervalsMinutes, 'minutes').valueOf(),
        end: dayjs(allowedInterval.end).add(10, 'years').valueOf()
      },
    ]);
  }

  public inEventIntervals(num: number): boolean {
    return this.intervalIndex(num) !== false;
  }

  public inSameEventInterval(interval: EventInterval): boolean {
    const index = this.intervalIndex(interval.start);
    if (index === false) return false;
    return interval.start >= this.intervals[index].start && interval.end <= this.intervals[index].end;
  }

  public nextAvailable(num: number): number | false {
    let current = num;
    let index = this.intervalIndex(current);
    while (index !== false) {
      const rounded = roundTimestamp(this.intervals[index].end, this.timeIntervalsMinutes, false);
      current = dayjs(rounded).add(this.timeIntervalsMinutes, 'minutes').valueOf();
      index = this.intervalIndex(current);
    }
    // the case when next available timestamp is after the last interval and allowed interval is set
    if (this.allowedIntervalSet && (current > this.intervals[this.intervals.length - 1].end)) return false;
    return current;
  }

  public prevAvailable(num: number): number | false {
    let current = num;
    let index = this.intervalIndex(current);
    while (index !== false) {
      const rounded = roundTimestamp(this.intervals[index].start, this.timeIntervalsMinutes, true);
      current = dayjs(rounded).add(-this.timeIntervalsMinutes, 'minutes').valueOf();
      index = this.intervalIndex(current);
    }
    // the case when prev. available timestamp is before the first interval and allowed interval is set
    if (this.allowedIntervalSet && (current < this.intervals[0].start)) return false;
    return current;
  }

  private intervalIndex(num: number): number | false {
    if (this.intervals.length === 0) return false;
    let i = 0;
    while (i < this.intervals.length && this.intervals[i].end < num) i++;
    if (i === this.intervals.length || num < this.intervals[i].start) return false;
    return i;
  }
}
