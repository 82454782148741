import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import './Card.scss';

type AlignType = 'top-left' | 'top-center' | 'top-right' | 'center-left' | 'center-center' | 'center-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

interface CardProps extends CustomElementProps {
  header?: string;
  headerAlign?: AlignType;
  bodyAlign?: AlignType;
  footerAlign?: AlignType;
  footer?: string;
}

const Card = (props: CardProps) => {
  const { header, className, footer, children, headerAlign, bodyAlign, footerAlign } = props;

  const alignSelector = (alignType: AlignType | undefined): string => alignType ? `align--${alignType}` : '';

  return (
    <div className={`spot-card--primary ${className || ''}`}>
      {header && (
        <header className={`spot-card--header ${alignSelector(headerAlign)}`}>
          {header}
        </header>
      )}
      <div className={`spot-card--body demo-card-height ${alignSelector(bodyAlign)}`}>
        <div className="spot-typography__text--body">{children}</div>
      </div>
      {footer && (
        <footer className={`spot-card--footer ${alignSelector(footerAlign)}`}>
          <div className="spot-typography__text--secondary">{footer}</div>
        </footer>
      )}
    </div>
  );
};

export default Card;
