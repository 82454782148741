export const RESOURCE_LEFT_NAV_DASHBOARD = 'left_nav:dashboard';
export const RESOURCE_LEFT_NAV_ROLES_MNGMNT = 'left_nav:roles_management';
export const RESOURCE_LEFT_NAV_USERS_MNGMNT = 'left_nav:users_management';
export const RESOURCE_LEFT_NAV_PRACTICE_UPGRADE = 'left_nav:practice_upgrade';
export const RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT = 'left_nav:practice_users_management';
export const RESOURCE_LEFT_NAV_SERVICE_EVENTS = 'left_nav:service_events';
export const RESOURCE_LEFT_NAV_UPGRADE_MNGMNT = 'left_nav:upgrade_management';
export const RESOURCE_LEFT_NAV_AUDIT = 'left_nav:audit';
export const RESOURCE_DASHBOARD_BUTTON_SCHEDULE_NOW = 'dashboard:button_schedule_now';

export const RESOURCE_DASHBOARD_BYPASS_ACKNOWLEDGEMENTS = 'dashboard:bypass_acknowledgements';

export const RESOURCE_USER_MANAGEMENT_ADD_USER = 'user_management:add_user';
export const RESOURCE_USER_MANAGEMENT_DELETE_USER = 'user_management:delete_user';
export const RESOURCE_USER_MANAGEMENT_UPDATE_USER = 'user_management:update_user';
export const RESOURCE_SERVICE_EVENTS_ADD = 'service_events:add_item';

export const RESOURCE_SERVICE_EVENTS_UPDATE = 'service_events:update_item';
export const RESOURCE_SERVICE_EVENTS_DELETE = 'service_events:delete_item';
export const CHECK_QUALIFICATIONS_QUALIFICATION_OVERRIDE = 'check_qualifications:qualification_override';

export const PERMISSION_VIEW = 'view';
export const PERMISSION_ALLOW = 'allow';

export const RESOURCE_GLOBAL_VALUES = 'global-values';
export const PERMISSION_UPDATE = 'update';

export interface Resource {
  resourceId: string;
  description?: string;
  type?: string;
}

export interface Permission {
  id: string;
  type?: string;
  checked?: boolean;
}

export interface ResourcePermission extends Resource {
  permissions: Permission[];
}

export interface ResourcesAndPermissions {
  resources: Resource[];
  permissions: Permission[];
}

export interface RoleApi {
  name: string;
  description?: string;
  level: number;
  global: boolean;
  hidden: boolean;
  resourcePermissions?: ResourcePermission[];
}

export interface RoleDefinition {
  name: string;
  level: number;
  global: boolean;
  default: boolean;
  description: string;
  resourcePermissions?: ResourcePermission[] | null;
}

export interface SapRole {
  sapId: string;
  roles: RoleApi[];
}

export interface SapResourcePermission {
  sapId: string;
  resourcePermissions: ResourcePermission[];
}

export interface Sap {
  id: string;
  name: string;
  integrations?: {
    vello?: boolean;
  };
}

export interface UserApi {
  email: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phone?: string;
  saps: Sap[];
  globalRoles?: RoleApi[] | null;
  globalResourcePermissions?: ResourcePermission[] | null;
  sapRoles?: SapRole[] | null;
  sapResourcePermissions?: SapResourcePermission[] | null;
  createdAt?: Date | null;
  lastAccessAt?: Date | null;
}

export interface EventApi {
  id: string;
  type: string;
  reason: string;
  startDateTime: number;
  endDateTime: number;
  createdBy: string;
}
