import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import TextBox from '../../components/TextBox/TextBox';
import { useStore } from '../../store/hooks';
import './VerificationCodeModalContent.scss';
import { runInAction } from 'mobx';
import { MAX_VERIFICATION_CODE_ATTEMPTS } from './VerificationCodeModalContentStore';

const VerificationCodeModalContent = observer(() => {
  const { verificationCodeModalContentStore: store, globalStore } = useStore();
  const { attemptsLeft, errorText } = store;
  const [verificationCode, setVerificationCode] = useState<string>('');

  const modalProps = globalStore.globalModalProps;

  useEffect(() => store.init(), [store]);

  useEffect(() => {
    runInAction(() => {
      modalProps.onCancel = () => store.resendCode();
      modalProps.onExit = () => store.closeModal();
      modalProps.onOk = () => store.submitCode(verificationCode);
    });
  }, [modalProps, store, verificationCode]);

  useEffect(() => {
    runInAction(() => modalProps.okDisabled = verificationCode.length !== 6 || attemptsLeft === 0);
  }, [attemptsLeft, modalProps, verificationCode.length]);

  useEffect(() => {
    if (attemptsLeft === MAX_VERIFICATION_CODE_ATTEMPTS) setVerificationCode('');
  }, [attemptsLeft]);

  const onChange = (newValue: string) => {
    if (/^\d{0,6}$/.test(newValue)) setVerificationCode(newValue);
  };

  return (
    <div className='verification-code-content_container'>
      <p>Please enter the code from your Cornerstone server.</p>
      <p>Click <strong>Resend Code</strong> to end a new code if it has expired or was entered incorrectly 3 times.</p>
      <TextBox
        className="verification-code-text-box"
        placeholder="Enter code and click Submit"
        ariaLabel="verification-code-input"
        disabled={modalProps.disabled}
        value={verificationCode}
        onChange={onChange}
        error={errorText}
      />
    </div>
  );
});

export default VerificationCodeModalContent;
