import { action, observable, runInAction } from 'mobx';
import utc from 'dayjs/plugin/utc';
import type { SortingObservable } from '../../components/Grid/Grid';
import { GridColDef } from '../../components/Grid/Grid';
import AbstractStore from '../../store/AbstractStore';
import dayjs from 'dayjs';
import { expandCollapseRenderer, successRenderer } from './renderers';
import type { AuditEvent } from '../../types/practice-types';
import { getPathWithQuery } from '../../utils/url-utils';
import { AUDIT_PATH } from '../../store/constants';

dayjs.extend(utc);

export default class AuditStore extends AbstractStore {
  private initialPaging = {
    totalEventsCount: 0,
    maxPagesToDisplay: 10,
    pageSize: 25,
    offset: 0,
  };

  private initialSort: SortingObservable<AuditEvent> = { field: 'id', order: 'DESC' };

  @observable loadingEvents = true;

  @observable events: AuditEvent[] = [];

  @observable paging = { ...this.initialPaging };

  sorting: SortingObservable<AuditEvent> = { ...this.initialSort };

  @observable expandedRowIds: string[] = [];


  public updatePagingOffset(pageNumber: number): any {
    this.paging.offset = this.paging.pageSize * (pageNumber - 1);
  }

  @action.bound
  public switchExpandedRow(row: AuditEvent): void {
    if (this.expandedRowIds.includes(row.id)) {
      this.expandedRowIds = this.expandedRowIds.filter(id => id !== row.id);
    } else {
      this.expandedRowIds = [...this.expandedRowIds, row.id];
    }
  }

  public get colDefs(): GridColDef[] {
    return [
      { headerName: '', field: 'expand', renderer: expandCollapseRenderer(this.switchExpandedRow), sortable: false },
      { headerName: 'Id', field: 'id', sortable: true },
      { headerName: 'Event', field: 'event', sortable: true },
      { headerName: 'User', field: 'userEmail', sortable: true },
      { headerName: 'Date (UTC)', field: 'date', sortable: true },
      { headerName: 'SAP ID', field: 'sapId', sortable: true },
      { headerName: 'Success', field: 'success', renderer: successRenderer, sortable: true },
    ];
  }

  public get availableTypes(): any[] {
    return [
      {value: "UPGRADE_TASK", label: "Upgrade task"},
      {value: "USER_MANAGEMENT", label: "User management"},
    ];
  }

  @action
  public async init(): Promise<void> {
    await this.fetchAuditEvents('', false, '', []);
  }

  @action
  public close(): void {
    this.sorting = { ...this.initialSort };
    this.paging = { ...this.initialPaging };
  }

  @action
  // @ts-ignore
  public async fetchAuditEvents(searchText: string, isFailuresOnly: boolean, minAuditId: string, types: string[]): Promise<void> {
    runInAction(() => this.loadingEvents = true);
    try {
      const url = getPathWithQuery(AUDIT_PATH, {
        sapId: this.sapId,
        auditPageSize: this.paging.pageSize,
        auditOffset: this.paging.offset,
        auditSortField: this.sorting.field,
        auditSortDirection: this.sorting.order,
        auditSearchText: searchText,
        auditFailuresOnly: isFailuresOnly,
        auditMinId: minAuditId || undefined,
        auditTypes: types
      });
      const response = await this.root.apiStore.callBackendApi<any>(url);
      for (const event of response.events) {
        event.date = dayjs(event.date).utcOffset(0).format('MM-D-YY HH:mm:ss');
      }
      runInAction(() => {
        this.events = response.events;
        this.paging.totalEventsCount = response.totalCount;
      });
    } catch (err: any) {
      runInAction(() => this.events = []);
    }
    runInAction(() => this.loadingEvents = false);
  }
}
