import React from 'react';
import { observer } from 'mobx-react';
import { Event } from '../../types/event-types';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import { useCheckSinglePermission } from '../../security/security-hook';
import { PERMISSION_ALLOW, RESOURCE_SERVICE_EVENTS_DELETE, RESOURCE_SERVICE_EVENTS_UPDATE } from '../../types/users-types';
import CalendarWithOffset from '../../components/CalendarWithOffset/CalendarWithOffset';
import dayjs from 'dayjs';
import { toLocalDateTimeWithTimezoneName } from './ServiceEventsStore';
import { localUtcOffset } from '../../utils/date-utils';
import TextBox from '../../components/TextBox/TextBox';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';

interface CommonRendererProps {
  row: Event<number>;
  index: number;
}

interface RowCellRendererProps extends CommonRendererProps {
  field: keyof Event<number>;
}

const RowCellRenderer = observer((props: RowCellRendererProps) => {
  const { row, index, field } = props;
  const { serviceEventsStore: store } = useStore();
  const { eventIntervalsHandler } = store;

  if (store.currentItemIndex === index) {
    if (field === 'startDateTime') {
      return (
        <>
          <TextBox
            ariaLabel={`table-cell-text-box_${field}`}
            inCell
            readonly
            value={toLocalDateTimeWithTimezoneName(store.currentItem.startDateTime)}
            disabled={store.loaders.action}
            error={store.currentItemError[field] as string}
          />
          <div className="date-wrapper">
            <CalendarWithOffset
              type="Date"
              value={store.currentItem.startDateTime}
              onChange={timestamp => runInAction(() => store.currentItem.startDateTime = timestamp)}
              eventIntervalsHandler={eventIntervalsHandler}
              utcOffset={localUtcOffset(new Date())}
            />
          </div>
          <div className="time-wrapper">
            <CalendarWithOffset
              type="Time"
              value={store.currentItem.startDateTime}
              onChange={timestamp => runInAction(() => store.currentItem.startDateTime = timestamp)}
              eventIntervalsHandler={eventIntervalsHandler}
              utcOffset={localUtcOffset(new Date())}
            />
          </div>
        </>
      );
    }
    if (field === 'endDateTime') {
      return (
        <>
          <TextBox
            ariaLabel={`table-cell-text-box_${field}`}
            inCell
            readonly
            value={toLocalDateTimeWithTimezoneName(store.currentItem.endDateTime)}
            disabled={store.loaders.action}
            error={store.currentItemError[field] as string}
          />
          <div className="date-wrapper">
            <CalendarWithOffset
              type="Date"
              value={store.currentItem.endDateTime}
              onChange={timestamp => runInAction(() => store.currentItem.endDateTime = timestamp)}
              eventIntervalsHandler={eventIntervalsHandler}
              utcOffset={localUtcOffset(new Date())}
            />
          </div>
          <div className="time-wrapper">
            <CalendarWithOffset
              type="Time"
              value={store.currentItem.endDateTime}
              onChange={timestamp => runInAction(() => store.currentItem.endDateTime = timestamp)}
              eventIntervalsHandler={eventIntervalsHandler}
              utcOffset={localUtcOffset(new Date())}
            />
          </div>
        </>
      );
    }
    if (field === 'reason') {
      return (
        <TextBox
          ariaLabel={`table-cell-text-box_${field}`}
          inCell
          value={store.currentItem[field] || ''}
          disabled={store.loaders.action}
          maxLength={100}
          onChange={v => runInAction(() => store.currentItem[field] = v)}
          focus
          error={index === store.currentItemIndex ? store.currentItemError[field] : undefined}
        />
      );
    }
  }
  return (
    <div
      className="plain-text item-editors"
      role="table-cell-text"
      aria-label={`item-${String(field)}`}
    >
      {field === 'startDateTime' || field === 'endDateTime' ? toLocalDateTimeWithTimezoneName(row[field] as number) : row[field]}
    </div>
  );
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const { serviceEventsStore: store } = useStore();

  const editMode = store.currentItemIndex !== undefined;
  const disabled = store.loaders.action;
  const deleteItemIsAllowed = useCheckSinglePermission(RESOURCE_SERVICE_EVENTS_DELETE, PERMISSION_ALLOW);
  const updateItemIsAllowed = useCheckSinglePermission(RESOURCE_SERVICE_EVENTS_UPDATE, PERMISSION_ALLOW);
  if (store.currentItemIndex === index) return <pre>
    <SpotIconButton
      path="save"
      disabled={disabled}
      onClick={() => store.validateAndUpdateItem()}
      title="Save changes"
      cypressData={`save-${row.id}-btn`}
    />
    <SpotIconButton
      path="cancel"
      disabled={disabled}
      onClick={() => store.cancelEditingItem()}
      title="Cancel changes"
      cypressData={`cancel-${row.id}-btn`}
    />
  </pre>;
  return <pre>
    <SpotIconButton
      path="edit"
      disabled={disabled || !updateItemIsAllowed || editMode || dayjs().valueOf() > row.startDateTime}
      onClick={() => store.editItem(index)}
      title="Edit event"
      cypressData={`edit-${row.id}-btn`}
    />
    <SpotIconButton
      path="delete"
      disabled={disabled || !deleteItemIsAllowed || editMode}
      onClick={() => store.deleteItem(index)}
      title="Delete event"
      cypressData={`delete-${row.id}-btn`}
    />
  </pre>;
});

// eslint-disable-next-line react/display-name
export const ConflictsExistMessage = (userEmail: string) => () => <>
  <p>The event was saved, but there were upgrades scheduled between the chosen start and end times.</p>
  <p>A report of impacted practices was emailed to <strong>{userEmail}</strong> and mycornerstonesupport@idexx.com</p>
</>;

export const rowCellRenderer = (field: keyof Event<number>) => {
  const rowCellRendererWrapper = (row: Event<number>, index: number) => <RowCellRenderer row={row} index={index} field={field} />;
  return rowCellRendererWrapper;
};

export const actionsRenderer = (row: Event<number>, index: any) => <ActionsRenderer row={row} index={index} />;
