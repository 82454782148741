import dayjs from 'dayjs';
import { EventInterval } from './event-intervals-handler';

const TIME_ZONE_NAMES: Record<number, string> = {
  '-8': 'Pacific Standard Time',
  '-7': 'Mountain Standard Time',
  '-6': 'Central Standard Time',
  '-5': 'Eastern Standard Time',
  '-4': 'Atlantic Standard Time',
  '-3.5': 'Newfoundland Standard Time',
  '-3': 'West Greenland Time',
  '-2': 'UTC-2',
  '-1': 'Eastern Greenland Time',
  '0': 'GMT',
  '1': 'Central European Time',
  '2': 'Eastern European Time',
  '3': 'Further-Eastern European Time',
  '4': 'UTC+4',
  '5': 'UTC+5',
  '5.5': 'Indian Standard Time',
};

const DATE_FORMAT = 'MM-DD-YY hh:mm:ss';

export const timeZoneNameFromOffset = (offsetHours: number | undefined): string => {
  if (offsetHours === undefined) return 'UTC';
  const value = TIME_ZONE_NAMES[offsetHours];
  if (value) return value;
  return offsetHours > 0 ? `UTC+${offsetHours}` : `UTC${offsetHours}`;
};

export const toUtcFormattedDate = (date: Date | undefined | null): string => {
  if (!date) return '';
  return dayjs(date).utcOffset(0).format(DATE_FORMAT);
};

export const localUtcOffset = (date: Date): number => - date.getTimezoneOffset() / 60;

export const roundTimestamp = (timestamp: number, toMinutes: number, up: boolean): number => {
  const divisor = toMinutes * 60 * 1000;
  const reminder = timestamp % divisor;
  let rounded = timestamp - reminder;
  if (up && reminder > 0) rounded += divisor;
  return rounded;
};

export const allowedUpgradeEventInterval = (): EventInterval =>
  ({ start: dayjs().add(1, 'day').valueOf(), end: dayjs().add(15, 'day').valueOf() });
