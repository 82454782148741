import type { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider';

export type Env = 'local' | 'dev' | 'qa' | 'stage' | 'prod';

const getEnv = (): Env => {
  if (location.origin.includes('local')) return 'local';
  if (location.origin.includes('dev')) return 'dev';
  if (location.origin.includes('qa')) return 'qa';
  if (location.origin.includes('stage')) return 'stage';
  return 'prod';
};

export const chooseConfig = <T>(configs: Record<Env, T>): T => configs[getEnv()];

const auth0Configs: Record<Env, Auth0ProviderOptions> = {
  local: {
    domain: 'auth-dev1-login.idexx.com',
    clientId: 'tAjPpMnpSGBTMlwsVKNsKlgvUthTaDtj',
  },
  dev: {
    domain: 'auth-dev1-login.idexx.com',
    clientId: 'tAjPpMnpSGBTMlwsVKNsKlgvUthTaDtj',
  },
  qa: {
    domain: 'auth-dev1-login.idexx.com',
    clientId: 'tAjPpMnpSGBTMlwsVKNsKlgvUthTaDtj',
  },
  stage: {
    domain: 'auth-np-login.idexx.com',
    clientId: 'QmWBsENuLrEO2EwElMeIbZyAZOkHs0Kd',
  },
  prod: {
    domain: 'auth-login.idexx.com',
    clientId: 'FSaORhD0HKReYwLUEdv71KathCkASnwU',
  },
};

let _auth0ProviderOptions: Auth0ProviderOptions | undefined;
export const auth0ProviderOptions = (): Auth0ProviderOptions => {
  if (!_auth0ProviderOptions) {
    _auth0ProviderOptions = {
      ...chooseConfig(auth0Configs),
      scope: '*',
      audience: 'https://www.idexx.com/myidexx',
      responseType: 'token',
    };
  }
  return _auth0ProviderOptions;
};

const backendUrls: Record<Env, string> = {
  local: 'http://localhost:3030/v1',
  dev: 'https://dev-cos-mycs-backend.testcosidexx.com/v1',
  qa: 'https://qa-cos-mycs-backend.testcosidexx.com/v1',
  stage: 'https://stage-cos-mycs-backend.cosidexx.com/v1',
  prod: 'https://prod-cos-mycs-backend.cosidexx.com/v1',
};

let _backendUrl: string | undefined = undefined;
export const backendUrl = (): string => {
  if (!_backendUrl) _backendUrl = chooseConfig(backendUrls);
  return _backendUrl;
};

export const refreshCheckQualificationsConfig = {
  interval: 5 * 60 * 1000, // 5 minutes
  maxAttempts: 12,
};
export const refreshServerUpgradeStatusConfig = {
  interval: 5 * 1000, // 5 seconds
  maxAttempts: 12,
};

export const vsaHosts = {
  vsa45: 'na1-idexx.kaseya.net',
  vsa6: 'na1-idexx02.kaseya.net',
};
