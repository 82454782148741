import React from 'react';
import './Header.scss';
import TopMenu from '../TopMenu/TopMenu';

const Header = () => (
  <div className='page-header_wrapper'>
    <div className='page-header'>
      <img src='/images/MyCornerstone_icon.svg' className='mycs-logo' />
      <span className='my-cornerstone'>IDEXX <strong>MyCornerstone</strong></span>
      <TopMenu />
    </div>
    <div className='page-bar' />
  </div>
);

export default Header;
