import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { auth0ProviderOptions } from '../config/app';
import { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider';
import type { AppState } from '@auth0/auth0-react';

const Auth0ProviderWithNavigate = ({ children }: any) => {
  const navigate = useNavigate();
  const authOptions: Auth0ProviderOptions = {
    ...auth0ProviderOptions(),
    redirectUri: `${window.location.origin}/callback`,
    onRedirectCallback: (appState: AppState | undefined) => {
      navigate(appState?.returnTo || '/');
    },
  };

  return (
    <Auth0Provider {...authOptions}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
