import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';
import './Audit.scss';
import Pagination from '../../components/Pagination/Pagination';
import { SubRowRenderer } from './renderers';
import CheckboxDropDown from "../../components/CheckboxDropDown/CheckboxDropDown";
import SearchBar from '../../components/SearchBar/SearchBar';
import Checkbox from '../../components/Checkbox/Checkbox';
import Button from '../../components/Button/Button';
import TextBox from '../../components/TextBox/TextBox';
import { useNavigate } from 'react-router-dom';

const Audit = observer(() => {
  const { auditStore: store } = useStore();
  const { loadingEvents, events, sorting, paging, expandedRowIds, availableTypes } = store;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [minAuditId, setAuditId] = useState<string>('');
  const [isFailuresOnly, setFailuresOnly] = useState<boolean>(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    store.init();
    return () => store.close();
  }, [store]);

  // @ts-ignore
  const search = (searchText, failuresOnly, minAuditId, types) => {
    store.fetchAuditEvents(searchText, failuresOnly, minAuditId, types);
  };

  const updatePaging = (pageNumber) => {
    store.updatePagingOffset(pageNumber);
    setPageNumber(pageNumber);
  };

  const onPageClick = (e, newPageNumber) => {
    e.preventDefault();
    updatePaging(newPageNumber);
    search(searchText, isFailuresOnly, minAuditId, selectedTypes);
  };

  const onSortingChanged = () => {
    search(searchText, isFailuresOnly, minAuditId, selectedTypes);
  };

  const onFailuresChanged = (value) => {
    setFailuresOnly(value);
    search(searchText, value, minAuditId, selectedTypes);
  };

  const onRefresh = () => {
    search(searchText, isFailuresOnly, minAuditId, selectedTypes);
  };

  const onAuditIdChange = (value: string) => {
    if (value && (!/^[1-9]\d*$/.test(value) || value.length > 10)) {
      return;
    }
    setAuditId(value);
  };

  const onSelectedTypesChange = (types: string[]) => {
    setSelectedTypes(types);
    updatePaging(1);
    search(searchText, isFailuresOnly, minAuditId, types);
  };

  const subrow = {
    renderer: SubRowRenderer,
    actions: {},
  };

  return (
    <div className='audit-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'>Audit</div>
      </div>
      <div>
        <div className="search-bar-wrapper">
          <SearchBar
            className='skeleton'
            ariaLabel='label-search'
            disabled={loadingEvents}
            onChange={value => setSearchText(value)}
            maxLength={40}
            initialValue=""
            placeholder='Search User Email, Event, Type, SAP ID, Agent Name'
            onSearch={value => search(value, isFailuresOnly, minAuditId, selectedTypes)}
          />
        </div>
        <Checkbox
          id='failures-only'
          ariaLabel='failures-only'
          className='failures-only skeleton'
          disabled={loadingEvents}
          checked={isFailuresOnly}
          onChange={onFailuresChanged}
        >
          Show Failures Only
        </Checkbox>
        <Button className='refresh-btn skeleton' type='primary' size='small' onClick={onRefresh} disabled={loadingEvents}>Refresh</Button>

        <div className="jump-to-audit-id-wrapper">
          <TextBox
            id="jump-to-audit-id"
            className="jump-to-audit-id-text-box"
            disabled={loadingEvents}
            value={minAuditId}
            onChange={onAuditIdChange}
            placeholder="Start at ID"
          />
        </div>
        <div className="type-dropdown-wrapper">
          <CheckboxDropDown isDisabled={loadingEvents} options={availableTypes} onChange={onSelectedTypesChange}/>
        </div>
      </div>

      <div className='audit-table'>
        <Grid
          id='audit-grid'
          loading={loadingEvents}
          disabled={loadingEvents}
          columns={store.colDefs}
          data={events}
          sorting={sorting}
          onSortingChanged={onSortingChanged}
          noRowsText='No data'
          rowIdField='id'
          expandedRowIds={expandedRowIds}
          subrow={subrow}
        />
      </div>
      {paging.totalEventsCount > 0 &&
          <div className="audit-table-pagination">
              <Pagination
                  currentPage={pageNumber}
                  total={paging.totalEventsCount}
                  showPagesMax={paging.maxPagesToDisplay}
                  pageSize={paging.pageSize}
                  onClick={onPageClick}
              />
          </div>
      }
      <div className="audit_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' onClick={() => navigate('/')}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default Audit;
