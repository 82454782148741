import React from 'react';
import { observer } from 'mobx-react';
import { User } from '../../types/practice-types';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import { checkSinglePermission } from '../../security/security-hook';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import { PERMISSION_ALLOW, RESOURCE_USER_MANAGEMENT_DELETE_USER, RESOURCE_USER_MANAGEMENT_UPDATE_USER } from '../../types/users-types';
import TextBox from '../../components/TextBox/TextBox';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';

interface CommonRendererProps {
  row: User;
  index: number;
}

interface RowCellRendererProps extends CommonRendererProps {
  field: keyof User;
}

const RowCellRenderer = observer((props: RowCellRendererProps) => {
  const { row, index, field } = props;
  const { usersManagementStore: store, globalStore } = useStore();
  const changeValue = (field: keyof User, value: string): void => {
    if (field === 'roles' || field === 'createdAt' || field === 'lastAccessAt' || (field === 'phone' && !/^\d*$/.test(value))) return;
    runInAction(() => {
      store.currentUser[field] = value;
    });
  };
  const checkBoxDisabled = (checkBoxIndex: number): boolean => {
    if (store.loaders.userAction) {
      return true;
    }
    const currentUser = globalStore.root.apiStore.user;
    return currentUser?.email === store.users[index].email && (currentUser?.globalRoles?.some(gr => gr.name === store.roles[checkBoxIndex].name) || false);
  };

  if (store.currentUserIndex === index && field !== 'roles' && (store.addMode || field !== 'email')) {
    return (
      <TextBox
        ariaLabel={`table-cell-text-box_${field}`}
        placeholder={field === 'phone' ? 'Add Phone' : field === 'email' ? 'MyIDEXX email' : ''}
        inCell
        value={store.currentUser[field] || ''}
        disabled={store.loaders.userAction}
        maxLength={field === 'phone' ? 10 : undefined}
        onChange={v => changeValue(field, v)}
        focus={field === 'firstName' || index === 0}
        error={index === store.currentUserIndex ? store.currentUserError[field] : undefined}
      />
    );
  }
  if (store.currentUserIndex === index && field === 'roles') {
    return (
      <CheckboxGroup
        id="practice-roles-group"
        groupClass="item-editors"
        type="horizontal"
        small
        values={store.roles}
        displayField="description"
        onChange={indices => store.changeRoleIndices(index, indices)}
        checkedIndices={store.currentUserRoleIndices}
        disabled={(index) => checkBoxDisabled(index)}
      />
    );
  }
  return (
    <div
      className="plain-text item-editors"
      role="table-cell-text"
      aria-label={`user-${field}`}
    >
      {field === 'roles' ? row.roles.filter(r => !r.hidden).map(r => r.description).join(', ') : row[field]}
    </div>
  );
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { index, row } = props;
  const { usersManagementStore: store, globalStore } = useStore();

  const editMode = store.currentUserIndex !== undefined;
  const disabled = store.loaders.userAction;
  const deleteUserIsAllowed = checkSinglePermission(globalStore.root.apiStore.userGlobalPermissions, RESOURCE_USER_MANAGEMENT_DELETE_USER, PERMISSION_ALLOW);
  const updateUserIsAllowed = checkSinglePermission(globalStore.root.apiStore.userGlobalPermissions, RESOURCE_USER_MANAGEMENT_UPDATE_USER, PERMISSION_ALLOW);
  if (store.currentUserIndex === index) return <pre>
    <SpotIconButton
      path="save"
      disabled={disabled}
      onClick={() => store.validateAndUpdateUser()}
      title="Save changes"
      cypressData={`save-${row.email}-btn`}
    />
    <SpotIconButton
      path="cancel"
      disabled={disabled}
      onClick={() => store.cancelEditingUser()}
      title="Cancel changes"
      cypressData={`cancel-${row.email}-btn`}
    />
  </pre>;
  return <pre>
    <SpotIconButton
      path="edit"
      disabled={disabled || !updateUserIsAllowed || editMode}
      onClick={() => store.editUser(index)}
      title="Edit user"
      cypressData={`edit-${row.email}-btn`}
    />
    <SpotIconButton
      path="delete"
      disabled={disabled || !deleteUserIsAllowed || editMode}
      onClick={() => store.deleteUser(row.email)}
      title="Delete user"
      cypressData={`delete-${row.email}-btn`}
    />
  </pre>;
});

export const rowCellRenderer = (field: keyof User) => {
  const rowCellRendererWrapper = (row: User, index: number) => <RowCellRenderer row={row} index={index} field={field} />;
  return rowCellRendererWrapper;
};

export const actionsRenderer = (row: User, index: any) => <ActionsRenderer row={row} index={index} />;
