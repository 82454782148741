import React from 'react';

import './Pagination.scss';

type PageType = {
  page: number;
  onClick: any;
};

const Page = (props: PageType) => {
  const { page, onClick } = props;
  return (
    <span className="spot-paging__button">
      <a href="#" data-page={page} onClick={(e: React.SyntheticEvent) => onClick(e, page)} data-cy={`pagination-page-${page}`}>
        {page}
      </a>
    </span>
  );
};

type PagePrevNextType = {
  buttonType: 'prev' | 'next';
  page: number;
  display: boolean;
  onClick: any;
};

const PagePrevNext = (props: PagePrevNextType) => {
  const { buttonType, page, display, onClick } = props;
  return display ? (
    <span className="spot-paging__button">
      <a href="#" data-page={page} title="" onClick={((e: React.SyntheticEvent) => onClick(e, page))} data-cy={`pagination-${buttonType}`}>
        {buttonType === 'next' ? '»' : '«'}
      </a>
    </span>
  ) : null;
};

type PaginationType = {
  total: number;
  currentPage: number;
  showPagesMax?: number;
  pageSize: number;
  onClick: any;
};

const Pagination = (props: PaginationType) => {
  const { total, currentPage, showPagesMax, pageSize, onClick } = props;
  const pages = Math.ceil(total / pageSize);

  const renderPage = (pageIndex) => {
    const page = pageIndex + 1;

    const limit = 8;
    const preservedDistanceToEdge = showPagesMax || 4;
    const distanceToLastPage = Math.abs(pages - page);
    const distanceToCurrent = Math.abs(page - currentPage);
    const isEdgePage = page === pages || page === 1;
    const isLastPreservedRange = (pages - currentPage) < preservedDistanceToEdge && (distanceToLastPage < preservedDistanceToEdge);
    let isFirstPreservedRange = page <= preservedDistanceToEdge + 1 && currentPage <= preservedDistanceToEdge + 1;
    const configs = { page };

    if (currentPage === preservedDistanceToEdge + 1 && pages > limit) {
      isFirstPreservedRange = false;
    }

    if (page === currentPage) {
      return <span className="spot-paging__button spot-paging__button--selected" key={page}>{page}</span>;
    }

    /* truncated */
    if (pages >= limit
      && currentPage !== page
      && !isEdgePage
      && !isFirstPreservedRange
      && !isLastPreservedRange
      && distanceToCurrent > 1) {
      return <span className="pagination_truncated" key={page}>…</span>;
    }

    return <Page {...configs} {...props} key={page} />;
  };

  const pageArray = Array.from(Array(pages).keys());
  const prev = currentPage - 1;
  const next = currentPage + 1;

  return (
    <div className="spot-paging">
      <div className="spot-paging__group">
        <PagePrevNext
          buttonType="prev"
          display={currentPage !== 1}
          page={prev}
          onClick={onClick}
        />
        {pageArray.map((page) => renderPage(page))}
        <PagePrevNext
          buttonType="next"
          display={currentPage !== pages}
          page={next}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Pagination;
