import { action, computed, observable, runInAction } from 'mobx';
import type { GridColDef, SortingObservable } from '../../components/Grid/Grid';
import AbstractStore from '../../store/AbstractStore';
import { ConflictsExistMessage, actionsRenderer, rowCellRenderer } from './renderers';
import { EVENTS_PATH } from '../../store/constants';
import type { Event } from '../../types/event-types';
import { EventResponse, EventType } from '../../types/event-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { sortByField } from '../../utils/object-utils';
import React from 'react';
import EventIntervalsHandler from '../../utils/event-intervals-handler';
import { roundTimestamp } from '../../utils/date-utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const emptyItem = (): Event<number> => ({ reason: '', type: '', startDateTime: 0, endDateTime: 0, createdBy: '' });

const emptyErrorItem = (): Event<string> => ({ reason: '', type: '', startDateTime: '', endDateTime: '', createdBy: '' });
const isEmptyItem = (m: Event<any>): boolean => Object.keys(m).every(key => !m[key]);
const equalItems = (it1: Event<any>, it2: Event<any>): boolean => Object.keys(it1).every(key => it1[key] === it2[key]);

export const toLocalDateTimeWithTimezoneName = (timestamp: number | Date): string => {
  return dayjs(timestamp).format('D-MMM YYYY[, at ]h:mm a') + ' ' + dayjs.tz.guess();
};

interface Loaders {
  page?: boolean;
  action?: boolean;
}

export default class ServiceEventsStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable items: Event<number>[] = [];

  @observable sorting: SortingObservable<Event<number>> = {};

  @observable eventIntervalsHandler: EventIntervalsHandler = new EventIntervalsHandler([], 30);

  @observable error: string | null = null;

  @observable currentItem: Event<number> = emptyItem();

  @observable currentItemError: Event<string> = emptyErrorItem();

  @observable currentItemIndex: number | undefined = undefined;

  @observable addMode = false;

  @computed
  public get colDefs(): GridColDef[] {
    return [
      { headerName: 'Reason', field: 'reason', renderer: rowCellRenderer('reason'), sortable: true },
      { headerName: 'Start', field: 'startDateTime', renderer: rowCellRenderer('startDateTime'), sortable: true },
      { headerName: 'End', field: 'endDateTime', renderer: rowCellRenderer('endDateTime'), sortable: true },
      { headerName: 'Scheduled By', field: 'createdBy', sortable: true },
      { headerName: 'Actions', field: 'actions', renderer: actionsRenderer },
    ];
  }

  @computed
  public get sortedItems(): Event<number>[] {
    let sorted = this.items.filter(i => !equalItems(i, emptyErrorItem()));
    if (this.sorting.field) {
      sorted = sortByField(sorted, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        sorted = sorted.reverse();
      }
    }
    if (this.addMode) sorted.push(emptyItem());
    return sorted;
  }

  public async init(): Promise<void> {
    runInAction(() => {
      this.loaders.page = true;
      this.addMode = false;
      this.sorting = { field: 'startDateTime', order: 'DESC' };
      this.eventIntervalsHandler.setAllowedInterval({ start: dayjs().valueOf(), end: dayjs().add(10, 'years').valueOf() });
    });
    await this.loadAllServiceEvents();
    runInAction(() => this.loaders.page = false);
  }

  @action
  public async refresh(): Promise<void> {
    if (this.loaders.action) return;
    runInAction(() => this.loaders.action = true);
    await this.loadAllServiceEvents();
    runInAction(() => this.loaders.action = false);
  }

  @action
  private async loadAllServiceEvents(): Promise<void> {
    const serviceEvents = await this.root.apiStore.findAllServiceEvents();
    runInAction(() => {
      this.items = serviceEvents;
      this.currentItemIndex = undefined;
    });
  }

  @action
  public addItem(): void {
    if (this.loaders.action) return;
    this.addMode = true;
    this.currentItem = emptyItem();
    this.currentItem.type = EventType.SCHEDULED_MAINTENANCE;
    this.currentItem.startDateTime = roundTimestamp(dayjs().valueOf(), 30, true);
    this.currentItem.endDateTime = dayjs(this.currentItem.startDateTime).add(30, 'minutes').valueOf();
    this.currentItemError = emptyErrorItem();
    this.currentItemIndex = this.sortedItems.length - 1;
  }

  @action
  public editItem(index: number): void {
    if (this.loaders.action) return;
    this.addMode = false;
    this.currentItem = { ...this.sortedItems[index] };
    this.currentItemError = emptyErrorItem();
    this.currentItemIndex = index;
  }

  @action
  public deleteItem(index: number): void {
    if (this.loaders.action) return;
    this.root.globalStore.showOkCancelPopup(
      'Confirm event deletion',
      'Are you sure you want to delete this event?',
      'OK',
      'Cancel',
      () => this.doDeleteItem(index),
    );
  }

  @action
  public async cancelEditingItem(): Promise<void> {
    if (this.currentItemIndex === undefined) return;
    this.addMode = false;
    this.currentItemIndex = undefined;
  }

  @action
  public async validateAndUpdateItem(): Promise<void> {
    if (this.currentItemIndex === undefined) return;
    // to prevent multiple calls of this method
    const currentIndex = this.currentItemIndex;
    runInAction(() => {
      this.currentItemIndex = undefined;
      const { reason, startDateTime, endDateTime } = this.currentItem;
      this.currentItemError = emptyErrorItem();
      if (!reason) {
        this.currentItemError.reason = 'Reason should be set';
      } else if (reason.length > 100) {
        this.currentItemError.reason = '100 character max';
      }
      if (startDateTime >= endDateTime) {
        this.currentItemError.startDateTime = 'Start should be before End';
        this.currentItemError.endDateTime = 'End should be after Start';
      }
    });
    if (!isEmptyItem(this.currentItemError)) {
      runInAction(() => this.currentItemIndex = currentIndex);
    } else {
      if (!equalItems(this.currentItem, this.sortedItems[currentIndex])) {
        await this.updateItem(currentIndex);
      }
      runInAction(() => this.currentItemIndex = undefined);
    }
  }

  private async updateItem(currentIndex: number): Promise<void> {
    const addMode = this.addMode;
    runInAction(() => {
      this.loaders.action = true;
      this.addMode = false;
    });
    try {
      const item = await this.root.apiStore.backendPost<EventResponse>(EVENTS_PATH, {
        action: addMode ? 'CREATE' : 'UPDATE',
        ...this.currentItem,
      });
      runInAction(() => {
        if (addMode) {
          this.items.push(item);
        } else {
          const index = this.items.findIndex(item => item.id === this.sortedItems[currentIndex].id);
          if (index >= 0) this.items[index] = item;
        }
      });
      if (item.isConflict) {
        this.root.globalStore.showOkPopup(
          'Event Saved - Conflicts Exist',
          React.createElement(ConflictsExistMessage(this.root.apiStore.userEmail)),
        );
      } else {
        this.root.globalStore.showOkPopup(
          'Event Saved',
          'There were no upgrades scheduled between the chosen start and end times.',
        );
      }
    } finally {
      runInAction(() => this.loaders.action = false);
    }
  }

  private async doDeleteItem(currentIndex: number): Promise<void> {
    if (this.loaders.action) return;
    runInAction(() => this.loaders.action = true);
    try {
      const id = this.sortedItems[currentIndex].id;
      await this.root.apiStore.backendPost(EVENTS_PATH, {
        action: 'DELETE',
        id,
      });
      runInAction(() => this.items = this.items.filter(e => e.id !== id));
    } catch {
      // ignore
    }
    runInAction(() => {
      this.currentItemIndex = undefined;
      this.loaders.action = false;
    });
  }
}
