import React, { ReactElement } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Provider as MobXProvider } from 'mobx-react';
import Auth0ProviderWithNavigate from './providers/Auth0ProviderWithNavigate';
import paths from './paths';
import './styles/index.scss';
import RootStore from './store/RootStore';
import Callback from './pages/Callback/Callback';
import { attachWalkMeScript } from './config/walkme';

attachWalkMeScript();

const rootStore = new RootStore();

const App = (): ReactElement => (
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <MobXProvider {...rootStore}>
        <Routes>
          {paths.filter(path => path.route).map(path => {
            const { Component, Layout } = path;
            return <Route key={path.route} path={path.route} element={<Layout><Component/></Layout>} />;
          })}
          <Route path='/callback' element={<Callback />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </MobXProvider>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById('root'));
