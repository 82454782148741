import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable } from 'mobx';
import type { PathType } from '../../paths';
import paths, { getPathPermissions } from '../../paths';
import { ResourcePermission } from '../../types/users-types';

export default class SideMenuStore extends AbstractStore {
  @observable currentPathItem: PathType | undefined;

  @action
  public setCurrentPathItem(item: PathType): void {
    this.currentPathItem = item;
  }

  @computed
  public get allPathsPermissions(): ResourcePermission[] {
    return ([] as ResourcePermission[]).concat(...paths.map(path => getPathPermissions(path, this.currentPathItem)));
  }
}
