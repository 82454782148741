import { action, computed, observable, runInAction } from 'mobx';
import type { SortingObservable } from '../../components/Grid/Grid';
import { GridColDef } from '../../components/Grid/Grid';
import AbstractStore from '../../store/AbstractStore';
import { sortByField } from '../../utils/object-utils';
import { roleDefinitionRenderer } from './renderers';
import type { RoleDefinition } from '../../types/users-types';

interface Loaders {
  page?: boolean;
}

export default class RolesManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable sorting: SortingObservable<RoleDefinition> = {};

  @observable rolesData: RoleDefinition[] | null = null;

  @computed
  public get rolesFiltered(): RoleDefinition[] {
    let filtered = this.rolesData || [];
    if (this.sorting.field) {
      filtered = sortByField(filtered, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        filtered = filtered.reverse();
      }
    }
    return filtered;
  }

  @computed
  public get colDefs(): GridColDef<RoleDefinition>[] {
    return [
      { headerName: 'Name', field: 'name', sortable: true, renderer: roleDefinitionRenderer },
      { headerName: 'Description', field: 'description', sortable: true },
      { headerName: 'Level', field: 'level', sortable: true },
      { headerName: 'Global', field: 'global', sortable: true, renderer: row => row.global ? 'Yes' : 'No' },
      { headerName: 'Default', field: 'default', sortable: true, renderer: row => row.default ? 'Yes' : 'No' },
    ];
  }

  @action
  public async init(): Promise<void> {
    runInAction(() => this.loaders.page = true);
    if (!this.rolesData) {
      await this.fetchRoles();
    }
    runInAction(() => this.loaders.page = false);
  }

  @action
  public close(): void {
    this.sorting = {};
  }

  @action
  public async fetchRoles(): Promise<void> {
    try {
      const roles = await this.root.apiStore.findAllRoles();
      runInAction(() => this.rolesData = roles);
    } catch (err: any) {
      runInAction(() => this.rolesData = []);
    }
  }

  @action
  public showRolePermissions(row: RoleDefinition): void {
    this.root.roleManagementStore.roleName = row.name;
    this.navigate('/admin/role-management');
  }
}
