import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import TextBox from '../../components/TextBox/TextBox';
import { Contact } from '../../types/practice-types';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';

interface CommonRendererProps {
  row: Contact;
  index: number;
}

interface RowCellRendererProps extends CommonRendererProps {
  field: keyof Contact;
}

const RowCellRenderer = observer((props: RowCellRendererProps) => {
  const { row, index, field } = props;
  const { apiStore, confirmationStore: store } = useStore();

  const changeValue = (field: keyof Contact, value: string): void => {
    if (field === 'phone' && !/^\d*$/.test(value)) return;
    runInAction(() => {
      store.currentContact[field] = value;
    });
  };

  const mainEmailDifferent = store.currentContacts[0].email !== apiStore.userEmail;

  if (store.currentIndex === index && !(index === 0 && ['name', 'email'].includes(field) && !mainEmailDifferent)) {
    return (
      <TextBox
        id={`cell-text-box-${index}_${field}`}
        ariaLabel={`table-cell-text-box_${field}`}
        inCell
        value={store.currentContact[field] || ''}
        maxLength={field === 'phone' ? 10 : 50}
        onChange={v => changeValue(field, v)}
        focus={field === 'name' || index === 0}
        error={index === store.currentIndex ? store.contactError[field] : undefined}
        cypressData={`contact-${field}-${store.currentContact.email}-input`}
      />
    );
  }
  if (index > 0 || field !== 'phone' || mainEmailDifferent) {
    return (
      <div
        className='plain-text'
        role='table-cell-text'
        aria-label={`contact-${field}`}
        data-cy={`contact-${field}-${store.currentContact.email}-div`}
      >
        {row[field] || ' '}
      </div>
    );
  }
  return (
    <TextBox
      id={`cell-text-box-${index}_${field}`}
      ariaLabel={`table-cell-text-box_${field}`}
      inCell
      disabled
      value={store.currentContacts[index].phone || ''}
      placeholder='Add Phone'
      cypressData={`contact-phone-${store.currentContact.email}-input`}
    />
  );
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const store = useStore().confirmationStore;
  const editMode = store.currentIndex !== undefined;
  if (store.currentIndex === index) return <pre>
    <SpotIconButton
      path="save"
      onClick={() => store.validateAndUpdateContact()}
      title="Save changes"
      cypressData={`save-${row.email}-btn`}
    />
    <SpotIconButton
      path="cancel"
      onClick={() => store.cancelEditingContact()}
      title="Cancel changes"
      cypressData={`cancel-${row.email}-btn`}
    />
  </pre>;
  return <pre>
    <SpotIconButton
      path="edit"
      disabled={editMode}
      onClick={() => store.editContact(index)}
      title="Edit contact"
      cypressData={`edit-${row.email}-btn`}
    />
    {index > 0 && (
      <SpotIconButton
        path="delete"
        disabled={editMode || index === 0}
        onClick={() => store.deleteContact(index)}
        title="Delete contact"
        cypressData={`delete-${row.email}-btn`}
      />
    )}
  </pre>;
});


export const rowCellRenderer = (field: keyof Contact) => {
  const rowCellRendererWrapper = (row: Contact, index: number) => <RowCellRenderer row={row} index={index} field={field} />;
  return rowCellRendererWrapper;
};

export const actionsRenderer = (row: Contact, index: any) => <ActionsRenderer row={row} index={index} />;

export const MessageChosenTimeUnavailable = () => <>
  <p>MyCornerstone will be <strong>unavailable</strong> during the time you have selected.</p>
  <p>Click <strong>OK</strong> to return and schedule a new time.</p>
</>;
