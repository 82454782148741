import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { MyIDEXXUserType } from '../../types/brm-types';
import { useStore } from '../../store/hooks';
import Spinner from '../Spinner/Spinner';

interface AuthorizerProps {
  children: any;
}

const Authorizer = observer((props: AuthorizerProps) => {
  const { children } = props;
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading, error, user } = useAuth0<MyIDEXXUserType>();
  const store = useStore().apiStore;

  useEffect(() => {
    if (isAuthenticated) {
      store.authenticate(getAccessTokenSilently, user);
    }
  }, [getAccessTokenSilently, isAuthenticated, store, user]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void loginWithRedirect({
        login_hint: store.userEmail || undefined,
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, store.userEmail]);

  if (error) return (
    <div>
      <section id='error-page'>
        <pre role='global-error'>{JSON.stringify(error, null, 2)}</pre>
      </section>
    </div>
  );
  return store.isAuthenticated ? children : <Spinner text={store.loggingOut ? 'Signing you out' : 'One moment while we retrieve your affiliated practices'} />;
});

export default Authorizer;
