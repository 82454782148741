import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SideMenu from '../../components/SideMenu/SideMenu';
import './BaseLayout.scss';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Modal from '../../components/Modal/Modal';
import Authorizer from '../../components/Authorizer/Authorizer';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/named

type LayoutProps = {
  children: any;
};

const BaseLayout = observer((props: LayoutProps) => {
  const { children } = props;
  const store = useStore().globalStore;
  const navigate = useNavigate();

  useEffect(() => {
    store.init(navigate);
  }, [navigate, store]);

  return (
    <div className={`base-layout ${store.isLoading ? 'show-skeletons' : ''}`}>
      <Header />
      <div className='base-layout-content'>
        <div className='base-layout--left'>
          <SideMenu />
        </div>
        <div className='base-layout--right'>
          <Authorizer>
            {children}
          </Authorizer>
        </div>
      </div>
      <Modal {...store.globalModalProps} />
      <Footer />
    </div>
  );
});

export default BaseLayout;
