import { chooseConfig, Env } from './app';

let _vendorsUrl: string | undefined = undefined;

const vendorsUrls: Record<Env, string> = {
  local: 'https://local.app:3001',
  dev: 'https://dev-mycs-offers.testcosidexx.com',
  qa: 'https://qa-mycs-offers.testcosidexx.com',
  stage: 'https://stage-mycs-offers.cosidexx.com',
  prod: 'https://offers.mycornerstone.idexx.com',
};

export const vendorsUrl = (): string => {
  if (!_vendorsUrl) {
    _vendorsUrl = chooseConfig(vendorsUrls);
  }
  return _vendorsUrl;
};
