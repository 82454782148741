import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import SelectSearch, { DomProps, OptionSnapshot, SelectedOption, Snapshot, ValueProps } from 'react-select-search';
import 'react-select-search/style.css';
import './PracticeSelector.scss';
import { useStore } from '../../store/hooks';
import { Sap } from '../../types/users-types';
import { KeyCodes } from '../../utils/dom-utils';

interface PracticeSelectorProps {
  disabled?: boolean;
  cypressData?: string;
}

const PracticeSelector = observer((props: PracticeSelectorProps) => {
  const { disabled, cypressData } = props;
  const { globalStore } = useStore();
  const [search, setSearch] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Sap[]>([]);
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const componentDisabled = disabled || globalStore.userSaps.length < 2;

  useEffect(() => {
    setFilteredOptions(globalStore.userSaps);
  }, [globalStore.userSaps]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '');
    const valueToFilter = (e.target.value || '').trim().toLowerCase();
    const filtered = valueToFilter === '' ? globalStore.userSaps : globalStore.userSaps.filter(sap => {
      const { id, name } = sap;
      return (id && id.toLowerCase().includes(valueToFilter))
        || (name && name.toLowerCase().includes(valueToFilter));
    });
    setFilteredOptions(filtered);
  };

  const renderSelectValue = (valueProps: ValueProps, _snapshot: Snapshot, className: string) => {
    const keydownHandler = (e: any) => {
      if (KeyCodes.DOM_VK_ESCAPE === e.keyCode) {
        setSearchFocus(false);
        e.stopPropagation();
        return;
      }
      valueProps.onKeyDown(e);
    };

    return (
      <>
        <input
          {...valueProps as object}
          className={className}
          style={{ display: searchFocus ? 'inline' : 'none' }}
          ref={el => el && el.focus()}
          role="selector-search"
          type="text"
          value={search}
          placeholder='Enter Practice'
          onBlur={e => { setSearchFocus(false); valueProps.onBlur(e as any); }}
          onClick={e => e.stopPropagation()}
          onKeyDown={keydownHandler}
          onChange={handleSearch}
          data-cy={`${cypressData}-input`}
        />
        <div
          {...valueProps as object}
          role="selector-value"
          className={className}
          style={{ display: searchFocus ? 'none' : 'block', cursor: componentDisabled ? 'default' : 'pointer' }}
          onClick={() => setSearchFocus(!componentDisabled)}
          onFocus={e => !componentDisabled && (valueProps as any).onFocus(e)}
        >
          <span>{globalStore.selectedSap.id}</span>
          <span>{globalStore.selectedSap.name}</span>
          {!componentDisabled && <div className="down-arrow" />}
        </div>
      </>
    );
  };

  const renderOption = (props: DomProps, option: SelectedOption, _snapshot: OptionSnapshot, className: string) => {
    const { name, value } = option;
    return (
      // @ts-ignore
      <button {...props} role="selector-option" className={className} type='button' onClick={() => setSearchFocus(false)} data-cy={`${cypressData}-${value}-option`}>
        <div>{value}</div>
        <span>{name}</span>
      </button>
    );
  };

  if (globalStore.userSaps.length === 0) return null;

  return (
    <div className='practice-selector_container'>
      <SelectSearch
        options={filteredOptions.map(us => ({ name: us.name, value: us.id }))}
        renderValue={renderSelectValue}
        renderOption={renderOption}
        onChange={value => globalStore.changeSelectedSap(value as string, false)}
        search={true}
        value={globalStore.selectedSap.id}
        disabled={componentDisabled}
      />
    </div>
  );
});

export default PracticeSelector;
