import React, { useCallback, useEffect } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import Button from '../Button/Button';
import SpotIcon from '../SpotIcon/SpotIcon';
import { KeyCodes } from '../../utils/dom-utils';

export interface ModalProps extends CustomElementProps {
  id: string;
  header?: any;
  cancelCaption?: string;
  okCaption: string;
  show: boolean;
  onCancel?: () => any | Promise<any>;
  onExit: () => any | Promise<any>;
  onOk: () => any | Promise<any>;
  onClickOutside?: () => any | Promise<any>;
  disabled?: boolean;
  okDisabled?: boolean;
  cypressData?: string;
}

const Modal = (props: ModalProps) => {
  const { id, className, children, okCaption, cancelCaption, header, show, onCancel, onOk, onExit, onClickOutside, okDisabled, disabled, cypressData } = props;
  const keydownListener = useCallback((e: any) => {
    if (!show) return;
    if (e.keyCode === KeyCodes.DOM_VK_ESCAPE) {
      onExit();
      e.stopPropagation();
    } else if (e.keyCode === KeyCodes.DOM_VK_RETURN) {
      onOk();
      e.stopPropagation();
    }
  }, [onExit, onOk, show]);

  const windowClickListener = useCallback((evt: any) => {
    if (!evt.target.closest(`#${id}_inner`)) {
      onClickOutside && onClickOutside();
    }
  }, [id, onClickOutside]);

  useEffect(() => {
    window.addEventListener('keydown', keydownListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, [keydownListener]);

  useEffect(() => {
    if (onClickOutside) {
      if (show) {
        window.addEventListener('click', windowClickListener);
      } else {
        window.removeEventListener('click', windowClickListener);
      }
    }
    return () => {
      if (onClickOutside && show) {
        window.removeEventListener('click', windowClickListener);
      }
    };
  }, [onClickOutside, show, windowClickListener]);

  return (
    <div id={id} role="modal-container" className={`spot-modal__overlay ${show ? 'spot-modal__overlay--visible' : ''} ${className || ''}`}>
      <div id={`${id}_inner`} className="spot-modal" data-cy={cypressData}>
        <div className="spot-modal__header">
          <div className="spot-modal__titles">
            <h2 className="spot-modal__title">{header}</h2>
          </div>
          <button role="modal-exit-button" className="spot-modal__header-cancel-button" onClick={() => onExit()}>
            <SpotIcon className="spot-modal__header-cancel-button-icon" path="cancel" />
          </button>
        </div>
        <div className="spot-modal__content">
          <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top" />
          <div className="spot-modal__content-wrapper">
            <div className="spot-modal__copy" role="modal-message">{children}</div>
          </div>
          <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom" />
        </div>
        <div className="spot-modal__footer">
          <button
            style={{visibility: cancelCaption ? 'visible' : 'hidden'}}
            role="modal-cancel-button"
            className="spot-modal__footer-cancel-button"
            disabled={disabled}
            onClick={() => onCancel && onCancel()}
            data-cy={`${cypressData}-cancel-btn`}
          >
            {cancelCaption}
          </button>
          <Button
            type="primary"
            role="modal-ok-button"
            onClick={() => onOk()}
            disabled={okDisabled || disabled}
            cypressData={`${cypressData}-ok-btn`}
          >
            {okCaption}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
