import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import type { ModalProps } from '../../components/Modal/Modal';
import React from 'react';
import { MessageServerOffline } from './renderers';

export const MAX_VERIFICATION_CODE_ATTEMPTS = 3;

export default class VerificationCodeModalContentStore extends AbstractStore {
  @observable attemptsLeft = MAX_VERIFICATION_CODE_ATTEMPTS;

  @observable errorText = '';

  @computed
  private get modalProps(): ModalProps {
    return this.root.globalStore.globalModalProps;
  }

  @action
  public init(): void {
    this.attemptsLeft = MAX_VERIFICATION_CODE_ATTEMPTS;
    this.errorText = '';
  }

  @action
  public async resendCode(): Promise<void> {
    runInAction(() => this.modalProps.disabled = true);
    try {
      await this.root.apiStore.updatePracticeAdminVerificationCode(this.sapId);
    } catch (e: any) {
      if (e.message?.includes('Server offline')) {
        this.root.globalStore.showOkPopup('Server Offline', React.createElement(MessageServerOffline));
      } else {
        this.root.globalStore.showOkPopup('Error', e.message);
      }
    }
    runInAction(() => {
      this.init();
      this.modalProps.disabled = false;
    });
  }

  @action
  public async submitCode(verificationCode: string): Promise<void> {
    runInAction(() => this.modalProps.disabled = true);
    const result = await this.root.apiStore.submitPracticeAdminVerificationCode(this.sapId, verificationCode);
    if (result) {
      await this.root.apiStore.fetchCurrentUserDetails();
      this.closeModal();
      this.navigate('/practice/user-management');
    } else {
      runInAction(() => {
        this.errorText = 'Invalid code';
        this.attemptsLeft = this.attemptsLeft > 0 ? this.attemptsLeft - 1 : 0;
      });
    }
    runInAction(() => this.modalProps.disabled = false);
  }

  @action
  public closeModal(): void {
    this.modalProps.show = false;
  }
}
