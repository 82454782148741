import AbstractStore from '../../store/AbstractStore';
import { action, observable, runInAction } from 'mobx';
import { PRACTICE_PATH } from '../../store/constants';
import { ResultObject } from '../../types/common-types';

interface Loaders {
  accept?: boolean;
}
export default class AcknowledgmentsStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable ackStepIndex = -1;

  @action
  public init(): void {
    this.ackStepIndex = -1;
  }

  @action
  public acceptStep(index: number, accepted: boolean) {
    if (accepted) this.ackStepIndex = index;
    else this.ackStepIndex = index -1;
  }

  @action
  public async acceptAcknowledgements(): Promise<void> {
    runInAction(() => this.loaders.accept = true);
    try {
      const wrapped = await this.root.apiStore.backendPost<ResultObject<boolean>>(PRACTICE_PATH, {
        action: 'ACCEPT_ACKNOWLEDGEMENTS',
        sapId: this.sapId,
      });
      if (wrapped.result) {
        await this.root.apiStore.fetchCurrentUserDetails();
        await this.root.globalStore.fetchPracticeData(false);
        this.navigate('/check-qualifications');
      }
    } finally {
      runInAction(() => this.loaders.accept = false);
    }
  }
}
