import CheckQualifications from './pages/CheckQualifications/CheckQualifications';
import Dashboard from './pages/Dashboard/Dashboard';
import ScheduleUpgrade from './pages/ScheduleUpgrade/ScheduleUpgrade';
import Confirmation from './pages/Confirmation-Summary/Confirmation';
import React, { ReactElement } from 'react';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import GlobalStore from './store/GlobalStore';
import {
  PERMISSION_VIEW,
  RESOURCE_LEFT_NAV_DASHBOARD,
  RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
  RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT,
  RESOURCE_LEFT_NAV_ROLES_MNGMNT, RESOURCE_LEFT_NAV_SERVICE_EVENTS,
  RESOURCE_LEFT_NAV_USERS_MNGMNT,
  RESOURCE_LEFT_NAV_AUDIT,
  ResourcePermission, RESOURCE_LEFT_NAV_UPGRADE_MNGMNT,
} from './types/users-types';
import RolesManagement from './pages/RolesManagement/RolesManagement';
import RoleManagement from './pages/RolesManagement/RoleManagement';
import UsersManagement from './pages/UsersManagement/UsersManagement';
import Audit from './pages/Audit/Audit';
import PracticeUserManagement from './pages/PracticeUserManagement/PracticeUserManagement';
import SpotIcon from './components/SpotIcon/SpotIcon';
import ServiceEvents from './pages/ServiceEvents/ServiceEvents';
import UpgradeManagement from './pages/UpgradeManagement/UpgradeManagement';
import Acknowledgments from './pages/Acknowledgments/Acknowledgments';

type MENU_CATEGORY = 'MAIN' | 'PRACTICE' | 'PRACTICE_MANAGEMENT';

export interface PathType {
  Component?: any;
  Layout: any;
  category: MENU_CATEGORY;
  showInCategories: MENU_CATEGORY[];
  name?: string;
  item?: ReactElement;
  isSection?: boolean;
  enabled?: (store: GlobalStore) => boolean;
  display?: (store: GlobalStore) => boolean;
  route?: string;
  globalResourcePermissions?: PathResourcePermission[];
  sapResourcePermissions?: PathResourcePermission[];
}

export interface PathResourcePermission {
  resourceId: string;
  permissions: string[];
}

const paths: PathType[] = [
  {
    route: '/',
    name: 'Dashboard',
    Component: Dashboard,
    category: 'MAIN',
    showInCategories: ['MAIN', 'PRACTICE'],
    Layout: BaseLayout,
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_DASHBOARD,
        permissions: [PERMISSION_VIEW]
      }
    ],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_DASHBOARD,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
  {
    name: 'Administration',
    category: 'MAIN',
    showInCategories: ['MAIN'],
    isSection: true,
    Layout: BaseLayout,
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_ROLES_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
      {
        resourceId: RESOURCE_LEFT_NAV_USERS_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
      {
        resourceId: RESOURCE_LEFT_NAV_SERVICE_EVENTS,
        permissions: [PERMISSION_VIEW]
      },
      {
        resourceId: RESOURCE_LEFT_NAV_AUDIT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/admin/service-events',
    name: 'Service Events',
    Component: ServiceEvents,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_SERVICE_EVENTS,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/admin/upgrade-management',
    name: 'Upgrade Management',
    Component: UpgradeManagement,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_UPGRADE_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/admin/roles-management',
    name: 'Roles Management',
    Component: RolesManagement,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_ROLES_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/admin/role-management',
    category: 'MAIN',
    showInCategories: ['MAIN'],
    name: 'Role Management',
    Component: RoleManagement,
    Layout: BaseLayout,
  },
  {
    route: '/admin/users-management',
    name: 'User Management',
    Component: UsersManagement,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_USERS_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/admin/audit',
    name: 'Audit',
    Component: Audit,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
    globalResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_AUDIT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    item: <><SpotIcon path="add-a-user" />User Management</>,
    isSection: true,
    Layout: BaseLayout,
    category: 'PRACTICE_MANAGEMENT',
    showInCategories: ['PRACTICE_MANAGEMENT'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    route: '/practice/user-management',
    name: 'Create / Manage Users',
    Component: PracticeUserManagement,
    Layout: BaseLayout,
    category: 'PRACTICE_MANAGEMENT',
    showInCategories: ['PRACTICE_MANAGEMENT'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_USERS_MNGMNT,
        permissions: [PERMISSION_VIEW]
      },
    ],
  },
  {
    name: 'Software Upgrade',
    isSection: true,
    Layout: BaseLayout,
    category: 'PRACTICE',
    showInCategories: ['PRACTICE'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
  {
    route: '/acknowledgments',
    enabled: store => store.isLoggedIn,
    display: store => !store.upgradeScheduled,
    name: 'Step 1: Acknowledgments',
    Component: Acknowledgments,
    Layout: BaseLayout,
    category: 'PRACTICE',
    showInCategories: ['PRACTICE'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
  {
    route: '/check-qualifications',
    enabled: store => store.isLoggedIn,
    display: store => !store.upgradeScheduled,
    name: 'Step 2: Check Qualifications',
    Component: CheckQualifications,
    Layout: BaseLayout,
    category: 'PRACTICE',
    showInCategories: ['PRACTICE'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
  {
    route: '/schedule-upgrade',
    enabled: store => store.isLoggedIn && store.agentsReady,
    display: store => !store.upgradeScheduled,
    name: 'Step 3: Schedule Date & Time',
    Component: ScheduleUpgrade,
    Layout: BaseLayout,
    category: 'PRACTICE',
    showInCategories: ['PRACTICE'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
  {
    route: '/confirmation',
    enabled: store => store.isLoggedIn && store.agentsReady && store.scheduleUpgradeTimeSet,
    display: store => !store.upgradeScheduled,
    name: 'Step 4: Confirmation',
    Component: Confirmation,
    Layout: BaseLayout,
    category: 'PRACTICE',
    showInCategories: ['PRACTICE'],
    sapResourcePermissions: [
      {
        resourceId: RESOURCE_LEFT_NAV_PRACTICE_UPGRADE,
        permissions: [PERMISSION_VIEW]
      }
    ],
  },
];

export const isSapPrivate = (path: PathType | undefined): boolean => path && ['PRACTICE', 'PRACTICE_MANAGEMENT'].includes(path.category) || false;

export const getPathsForCategory = (category: MENU_CATEGORY): PathType[] => {
  return paths.filter(path => path.showInCategories.includes(category));
};

export const getPathPermissions = (path: PathType, currentPath: PathType | undefined): ResourcePermission[] => {
  if (!currentPath) return [];
  return (isSapPrivate(currentPath) ? path.sapResourcePermissions || [] : path.globalResourcePermissions || []).map(pathPerm => ({
    resourceId: pathPerm.resourceId,
    permissions: pathPerm.permissions.map(p => ({ id: p })),
  }));
};

export default paths;
