import React from 'react';
import Pill from '../../components/Pill/Pill';
import {observer} from 'mobx-react';
import {AuditEvent} from '../../types/practice-types';
import SpotIcon from "../../components/SpotIcon/SpotIcon";
import {copyToClipboard, isDefined} from "../../utils/object-utils";

interface CommonRendererProps {
  row: AuditEvent;
  index: number;
}

const SuccessRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  switch (row.success) {
    case true: return <Pill type="positive" small outline icon="checkmark">Success</Pill>;
    case false: return <Pill type="negative" small outline icon="cancel">Failure</Pill>;
    default: return null;
  }
});

export const displayEventField = (value: string, name: string) => {
  let truncatedValue = value || '';
  truncatedValue = truncatedValue.length > 100 ? truncatedValue.substring(0, 100) + " ..." : truncatedValue;
  return (
      <>
        <SpotIcon className="copy-to-clipboard-icon" path="copy" title={`Copy ${name} To Clipboard`} onClick={() => copyToClipboard(value || '')}/>
        {name}: <strong>{truncatedValue}</strong>
      </>
  );
};

const eventFields = [
  ['type', 'Type'],
  ['event', 'Event'],
  ['userEmail', 'User'],
  ['userRole', 'User Role(s)'],
  ['contactName', 'Contact Name'],
  ['contactPhone', 'Contact Phone'],
  ['agentName', 'Machine Name'],
  ['requestUrl', 'Request URL'],
  ['request', 'Request'],
  ['response', 'Response'],
  ['managementUserEmail', 'Managed User Email'],
  ['managementUserRoles', 'Managed User Role(s)'],
  ['managementUserFirstName', 'Managed User First Name'],
  ['managementUserLastName', 'Managed User Last Name'],
  ['managementUserPhone', 'Managed User Phone']
];

export const SubRowRenderer = (row: AuditEvent) => (
    <div>
      <ul>
        {eventFields.filter(([key]: string[]) => isDefined(row[key]))
          .map(([key, description]: string[], index) => (
            <li key={index}>
              {displayEventField(row[key], description)}
            </li>
          ))}
      </ul>
    </div>
);

interface ExpandCollapseProps extends CommonRendererProps {
  expandedRowIds?: string[];
  action: (row: any, index: any) => void;
}

const ExpandCollapseRenderer = observer((props: ExpandCollapseProps) => {
  const { row, action, expandedRowIds, index } = props;
  const isExpanded = expandedRowIds && expandedRowIds.includes(row.id);
  const iconPath = isExpanded ? 'caret-up' : 'caret-down';
  const iconHint = isExpanded ? 'Collapse' : 'Expand';
  return (
      <div className="expand-collapse-cell">
        <SpotIcon className="expand-collapse-icon" path={iconPath} ariaLabel={`agent-${iconPath}`} title={iconHint} onClick={() => action(row, index)} />
      </div>
  );
});

export const expandCollapseRenderer = (action: (row: AuditEvent) => void) => {
  const wrapper = (row: AuditEvent, index: number, _id: string, expandedRowIds: string[] | undefined) =>
      <ExpandCollapseRenderer row={row} index={index} expandedRowIds={expandedRowIds} action={action} />;
  return wrapper;
};

export const successRenderer = (row: AuditEvent, index: any) => <SuccessRenderer row={row} index={index} />;
