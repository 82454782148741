import React, { useState } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon from '../SpotIcon/SpotIcon';
import './SearchBar.scss';
import { KeyCodes } from '../../utils/dom-utils';

interface SearchBarProps extends CustomElementProps {
  children?: never;
  disabled?: boolean;
  initialValue: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  maxLength?: number;
  role?: never;
  fontSize?: number;
  cypressData?: string;
}

const SearchBar = (props: SearchBarProps) => {
  const { id, className, ariaLabel, disabled, placeholder, onChange, onSearch, initialValue, maxLength, fontSize, cypressData } = props;
  const [value, setValue] = useState(initialValue);

  const doSearch = (v: string) => {
    setValue(v.trim());
    onSearch(v.trim());
  };

  const keydownListener = (e: any) => {
    if (e.keyCode === KeyCodes.DOM_VK_ESCAPE) {
      setValue(initialValue);
      e.currentTarget.blur();
      e.stopPropagation();
    } else if (e.keyCode === KeyCodes.DOM_VK_RETURN) {
      doSearch(value);
      e.currentTarget.blur();
      e.stopPropagation();
    }
  };

  const onValueChange = (value: string) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const onClearClick = () => {
    if (!disabled) {
      onValueChange('');
      doSearch('');
    }
  };

  return (
    <div id={id} className={`spot-form spot-search-bar ${className || ''}`}>
      <input
        role="input"
        aria-label={ariaLabel}
        value={value}
        maxLength={maxLength}
        className="spot-form__input spot-search-bar__input"
        style={fontSize ? { fontSize: `${fontSize}px`} : undefined}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => onValueChange(e.target.value)}
        onKeyDown={keydownListener}
        data-cy={`${cypressData}-input`}
      />
      {value && <div className="spot-search-bar__clear" role="search-clear" onClick={onClearClick} data-cy={`${cypressData}-clear-btn`}>&#215;</div>}
      <button aria-label="search" className="spot-search-bar__search-button" disabled={disabled} onClick={() => doSearch(value)} data-cy={`${cypressData}-search-btn`}>
        <SpotIcon path="search" />
      </button>
    </div>
  );
};

export default SearchBar;
