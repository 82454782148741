import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon from '../SpotIcon/SpotIcon';
import './Checkbox.scss';

interface CheckboxProps extends CustomElementProps {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  noFocusBorder?: boolean;
  onChange?: (checked: boolean) => void;
  role?: never;
  small?: boolean;
  cypressData?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const { id, checked, disabled, onChange, className, children, ariaLabel, small, cypressData, noFocusBorder } = props;

  return (
    <label className={`spot-form__checkbox ${small ? 'spot-form__checkbox--small' : ''} ${noFocusBorder ? 'no-focus-border' : ''} ${className || ''}`} htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        role="checkbox"
        aria-label={ariaLabel}
        className="spot-form__checkbox-input"
        checked={checked || false}
        disabled={disabled}
        onChange={() => onChange && !disabled && onChange(!checked)}
        data-cy={cypressData}
      />
      <span className="spot-form__checkbox-inner">
        <span className="spot-form__checkbox-visual">
            <SpotIcon className="spot-form__checkbox-checked-icon" path="checkmark" />
            <SpotIcon className="spot-form__checkbox-indeterminate-icon" path="checkbox-indeterminate" />
        </span>
        <span className="spot-form__checkbox-label">{children}</span>
    </span>
    </label>
  );
};

export default Checkbox;
