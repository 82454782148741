import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';
import './RolesManagement.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

const RolesManagement = observer(() => {
  const { rolesManagementStore: store, globalStore } = useStore();
  const { loaders, rolesFiltered, sorting } = store;
  const navigate = useNavigate();

  useEffect(() => {
    store.init();
    return () => {
      store.close();
    };
  }, [navigate, store]);

  if (loaders.page) return <Spinner />;

  return (
    <div className='roles-management-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'>Roles</div>
      </div>

      <div className='roles-management-table'>
        <Grid
          id='roles-management-grid'
          loading={globalStore.isLoading}
          disabled={globalStore.isLoading}
          columns={store.colDefs}
          data={rolesFiltered}
          sorting={sorting}
          noRowsText='No data'
          rowIdField='roleId'
        />
      </div>
      <div className="roles-management_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' onClick={() => navigate('/')}>Dashboard</Button>
        </div>
      </div>

    </div>
  );
});

export default RolesManagement;
