export const EventType = {
  SCHEDULED_MAINTENANCE: 'SCHEDULED_MAINTENANCE',
  UPGRADE: 'UPGRADE',
} as const;

export interface Event<TDateType> {
  id?: string;
  type: string;
  reason: string;
  startDateTime: TDateType;
  endDateTime: TDateType;
  createdBy: string;
}

export interface EventResponse extends Event<number> {
  isConflict?: boolean;
}
