import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import { useStore } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { checkSinglePermission } from '../../security/security-hook';
import { PERMISSION_ALLOW, RESOURCE_SERVICE_EVENTS_ADD } from '../../types/users-types';
import { isDefined } from '../../utils/object-utils';
import './ServiceEvents.scss';

const ServiceEvents = observer(() => {
  const { serviceEventsStore: store, globalStore } = useStore();
  const { colDefs, sortedItems, loaders, sorting } = store;
  const navigate = useNavigate();

  useEffect(() => {
    store.init();
  }, [navigate, store]);

  if (loaders.page) return <Spinner />;

  const addItemBtnIsEnabled = checkSinglePermission(globalStore.root.apiStore.userGlobalPermissions, RESOURCE_SERVICE_EVENTS_ADD, PERMISSION_ALLOW);

  return (
    <div className='service-events-wrapper'>
      <div className='page-heading skeleton'>
        <div className='heading--level-1'>Service Events</div>
      </div>
      <Button
        className='refresh-btn skeleton'
        type='primary'
        size='small'
        onClick={() => store.refresh()}
        disabled={loaders.action}
      >
        Refresh List
      </Button>
      <Button
        className="add-more-btn"
        ariaLabel="add-more"
        type="link"
        size="small"
        icon="plus"
        disabled={ loaders.action || !addItemBtnIsEnabled }
        onClick={ () => store.addItem() }
      >
        Add Event
      </Button>
      {loaders.action && <Spinner size={24} />}
      <Grid
        id='service-events-grid'
        columns={colDefs}
        data={sortedItems}
        sorting={sorting}
      />
      <div className="service-events_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' disabled={loaders.action || isDefined(store.currentItemIndex)} onClick={() => navigate('/')}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default ServiceEvents;
