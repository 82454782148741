import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import './TableCellTextBox.scss';
import { KeyCodes } from '../../utils/dom-utils';

interface TableCellTextBoxProps extends CustomElementProps {
  disabled?: boolean;
  readonly?: boolean;
  initialValue: string | undefined;
  placeholder?: string;
  maxLength?: number;
  classNameForCancel?: string;
  onCancel: () => void;
  onSave: (value: string) => void;
  cypressData?: string;
}

const TableCellTextBox = (props: TableCellTextBoxProps) => {
  const { id, classNameForCancel, onCancel, onSave, initialValue, className, maxLength, placeholder, disabled, readonly, ariaLabel, cypressData } = props;
  const [value, setValue] = useState<string>(initialValue || '');
  const ref = useRef<any>();

  const doSave = useCallback(() => {
    value !== initialValue ? onSave(value) : onCancel();
  }, [initialValue, onCancel, onSave, value]);

  const keydownListener = (e: any) => {
    if (e.keyCode === KeyCodes.DOM_VK_ESCAPE) {
      onCancel();
      e.stopPropagation();
    } else if (e.keyCode === KeyCodes.DOM_VK_RETURN) {
      doSave();
      e.stopPropagation();
    }
  };

  const windowClickListener = useCallback((evt: any) => {
    if (classNameForCancel && evt.target.closest(`.${classNameForCancel}`)) {
      onCancel();
    } else if (!evt.target.closest(`#${id}`)) {
      doSave();
    }
  }, [classNameForCancel, doSave, id, onCancel]);

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.focus();
    window.addEventListener('click', windowClickListener);

    return () => {
      window.removeEventListener('click', windowClickListener);
    };
  }, [id, onCancel, onSave, value, windowClickListener]);

  const changeValue = (newValue: string) => {
    if (!maxLength || newValue.length <= maxLength) {
      setValue(newValue);
    }
  };

  return (
    <input
      id={id}
      ref={ref}
      role="input"
      aria-label={ariaLabel}
      className={`spot-form__input table-cell-text-box ${className || ''}`}
      type="text"
      placeholder={placeholder}
      maxLength={maxLength}
      value={value}
      readOnly={readonly}
      disabled={disabled}
      onChange={e => changeValue(e.target.value)}
      onKeyDown={keydownListener}
      data-cy={cypressData}
    />
  );
};

export default TableCellTextBox;
