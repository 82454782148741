import React from 'react';
import './ContentHeader.scss';

interface ContentHeaderProps {
  className?: string;
  children?: any;
  onClick?: () => void;
  cypressData?: string;
}

const ContentHeader = (props: ContentHeaderProps) => {
  const { className, children, onClick, cypressData } = props;
  return (
    <div className={`content-header ${className || ''}`} role="content-header" onClick={() => onClick && onClick()} data-cy={cypressData}>
      {children}
    </div>
  );
};

export default ContentHeader;
