import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import { RoleApi } from '../../types/users-types';
import TextBox from '../../components/TextBox/TextBox';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';

export interface UserForEditing {
  email: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phone?: string;
  roles: RoleApi[];
}

interface CommonRendererProps {
  row: UserForEditing;
  index: number;
}

interface RowCellRendererProps extends CommonRendererProps {
  field: keyof UserForEditing;
}


const RowCellRenderer = observer((props: RowCellRendererProps) => {
  const { row, index, field } = props;
  const store = useStore().practiceUserManagementStore;

  const changeValue = (field: keyof UserForEditing, value: string): void => {
    if (field === 'roles' || field === 'phone' && !/^\d*$/.test(value)) return;
    runInAction(() => {
      store.currentUser[field] = value;
    });
  };

  if (store.currentIndex === index && field !== 'roles' && (store.addMode || field !== 'email')) {
    return (
      <TextBox
        id={`cell-text-box-${index}_${field}`}
        ariaLabel={`table-cell-text-box_${field}`}
        placeholder={field === 'phone' ? 'Add Phone' : field === 'email' ? 'MyIDEXX email' : ''}
        inCell
        value={store.currentUser[field] || ''}
        disabled={store.loaders.user}
        maxLength={field === 'phone' ? 10 : undefined}
        onChange={v => changeValue(field, v)}
        focus={field === 'firstName' || index === 0}
        error={index === store.currentIndex ? store.userError[field] : undefined}
      />
    );
  }
  if (store.currentIndex === index && field === 'roles') {
    return (
      <CheckboxGroup
        id="practice-roles-group"
        groupClass="item-editors"
        type="horizontal"
        small
        values={store.roles}
        displayField="description"
        onChange={store.changeRoleIndices}
        checkedIndices={store.currentRoleIndices}
        disabled={() => store.loaders.user || false}
      />
    );
  }
  return (
    <div
      className="plain-text item-editors"
      role="table-cell-text"
      aria-label={`user-${field}`}
    >
      {field === 'roles' ? row.roles.filter(r => !r.hidden).map(r => r.description).join(', ') : row[field]}
    </div>
  );
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const store = useStore().practiceUserManagementStore;
  const editMode = store.currentIndex !== undefined;
  const disabled = store.loaders.user;
  if (store.currentIndex === index) return <pre>
    <SpotIconButton
      path="save"
      disabled={disabled}
      onClick={() => store.validateAndUpdateUser()}
      title="Save changes"
      cypressData={`save-${row.email}-btn`}
    />
    <SpotIconButton
      path="cancel"
      disabled={disabled}
      onClick={() => store.cancelEditingUser()}
      title="Cancel changes"
      cypressData={`cancel-${row.email}-btn`}
    />
  </pre>;
  return <pre>
    <SpotIconButton
      path="edit"
      disabled={disabled || editMode}
      onClick={() => store.editUser(index)}
      title="Edit user"
      cypressData={`edit-${row.email}-btn`}
    />
    <SpotIconButton
      path="delete"
      disabled={disabled || editMode}
      onClick={() => store.deleteUser(index)}
      title="Delete user"
      cypressData={`delete-${row.email}-btn`}
    />
  </pre>;
});

export const rowCellRenderer = (field: keyof UserForEditing) => {
  const rowCellRendererWrapper = (row: UserForEditing, index: number) => <RowCellRenderer row={row} index={index} field={field} />;
  return rowCellRendererWrapper;
};

export const actionsRenderer = (row: UserForEditing, index: any) => <ActionsRenderer row={row} index={index} />;
