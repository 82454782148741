import dayjs from 'dayjs';
import React from 'react';
import { observer } from 'mobx-react';
import { isUpgradeScheduled, PracticeConfig } from '../../types/practice-types';
import Button from '../../components/Button/Button';
import Pill from '../../components/Pill/Pill';
import { useStore } from '../../store/hooks';
import Spinner from '../../components/Spinner/Spinner';

interface CommonRendererProps {
  row: PracticeConfig;
  index: number;
}

export const MessageCancelUpgrade = () => <>
  <p>Are you sure you want to cancel this upgrade?</p>
  <p>There is no rollback for this operation.</p>
</>;

export const MessageCancelUpgradeError = () => <>
  <p>MyCornerstone encountered an error while cancelling the upgrade.</p>
  <p>An email has been sent to mycornerstonesupport@idexx.com and the practice contacts have been notified.</p>
  <p>Please review the summary report and Kaseya for more details.</p>
</>;

export const MessageCancelUpgradeCommunicationError = () => <>
  <p>The practice&apos;s upgrade has been cancelled, but no communications were sent.</p>
  <p>Use the Summary Report to collect contact information from the practice and notify them of the cancellation.</p>
  <p>Email mycornerstonesupport@idexx.com as well to notify the MyCornerstone team.</p>
</>;

const StatusRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  const status = (row.scheduleUpgradeStatus || '').replaceAll(/^(.*)\s+\(.*$/g, '$1');
  const caption = `${status} (${row.scheduleError === 'Kaseya' ? 'Error' : 'Success'})`;
  if (!row.scheduleError) return <Pill type="positive" icon="checkmark" small outline title="No Errors">{caption}</Pill>;
  if (row.scheduleError === 'Communication') {
    return <Pill type='warning' icon='alert-notification' small outline title='Communications to practice contacts failed but upgrade was cancelled'>{caption}</Pill>;
  }
  return <Pill type="negative" icon="cancel" small outline title="Errors, check summary report and Kaseya">{caption}</Pill>;
});

const ContactsRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  return <>
    {(row.contacts || []).map((contact, index) => (
      <React.Fragment key={contact.email}>
        {index > 0 ? ', ' : ''}
        <span className="contact-name">{contact.name}</span>
      </React.Fragment>
    ))}
  </>;
});

const ReportRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  if (!row.pdfUrl) return null;
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <a href={row.pdfUrl} target="_blank" rel="noopener noreferrer" className="doc-link">
        <img src="/images/pdf_document_icon.svg" className="doc-icon" alt="PDF icon" title="Click to view the report"/>
      </a>
    </div>
  );
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const { upgradeManagementStore: store } = useStore();
  const { cancellingItemIndex, cancelling } = store;
  if (!isUpgradeScheduled(row) || !row.scheduleUpgradeTimestamp
    || dayjs(row.scheduleUpgradeTimestamp).isBefore(dayjs())) return null;
  if (cancellingItemIndex === index) return <Spinner size={24} />;
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
        type="link"
        size="small"
        disabled={cancelling}
        onClick={() => store.promptCancelUpgrade(index)}
      >
        Cancel And Notify
      </Button>
    </div>
  );
});

export const statusRenderer = (row: PracticeConfig, index: any) => <StatusRenderer row={row} index={index} />;
export const contactsRenderer = (row: PracticeConfig, index: any) => <ContactsRenderer row={row} index={index} />;
export const reportRenderer = (row: PracticeConfig, index: any) => <ReportRenderer row={row} index={index} />;
export const actionsRenderer = (row: PracticeConfig, index: any) => <ActionsRenderer row={row} index={index} />;
export const dateRenderer = (timestamp: number | undefined) => timestamp ? dayjs(timestamp).format('MM-DD-YY HH:mm') : '' ;
