import React from 'react';

export const MessageWithPracticeAdmin = () => <>
  <p>We see that you do not have permissions to access this task.</p>
  <p>Please contact your Practice Admin to continue.</p>
</>;

export const MessageWithoutPracticeAdmin = () => <>
  <p>Your practice must have at least one administrative user to access the task.</p>
  <p>Click the User Management card to create your first practice admin.</p>
</>;

export const MessageSupportAssistanceRequired = () => <>
  <p>Your Cornerstone configuration requires a Cornerstone support technician to create your first practice admin user.</p>
  <p>Please contact Cornerstone Support at <strong>1-800-695-2877</strong> to continue.</p>
</>;

export const MessageSendVerificationCode = (props: { practiceName: string }) => <>
  <p>To verify your affiliation with <strong>{props.practiceName}</strong>, a 6 digit code will be sent to your Cornerstone server.</p>
  <p>The code will be saved to the desktop in a text file named <strong>MyCornerstone Verification Code</strong>.</p>
  <p>Open the file to retrieve the code and enter it here. The code will expire <strong>10 minutes</strong> after it is sent.</p>
</>;

export const MessageServerOffline = () => <>
  <p>It looks like your Cornerstone Server is off or not connected to the Internet.</p>
  <p>Ensure it is on and connected before continuing, or contact Cornerstone Support at <strong>1-800-695-2877</strong> for assistance.</p>
</>;

export const MessageMultipleServers = () => <>
  <p>It looks like more than one computer was configured as a Cornerstone server recently.</p>
  <p>Please contact Cornerstone Support at <strong>1-800-695-2877</strong> to identify your true server before continuing.</p>
</>;

export const MessageNoServer = () => <>
  <p>It looks like we cannot identify your Cornerstone server.</p>
  <p>Please contact Cornerstone Support at <strong>1-800-695-2877</strong> to identify your server before continuing.</p>
</>;

export const MessagePracticeUpgradeLocked = (props: { userName: string | undefined }) => <>
  <p><strong>{props.userName}</strong> is working on the upgrade task.</p>
  <p>Please try again once their session ends.</p>
</>;

export const MessageServerStatusUnavailable = () => <>
  <p>We see you had an upgrade scheduled, but cannot determine if it was completed.</p>
  <p>Please make sure your Cornerstone server is on and connected to the internet and try again.</p>
</>;

export const MessageNoUpgradeRequired = () => <>
  <p>It looks like your Cornerstone server already has the latest version installed.</p>
  <p>If you have any workstations which need to be upgraded, they must be upgraded manually.</p>
</>;
