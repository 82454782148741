import { chooseConfig, Env } from './app';

const nonProdUrl = 'https://cdn.walkme.com/users/d2a25d237e044f35a33d831d38432af7/test/walkme_d2a25d237e044f35a33d831d38432af7_https.js';
const prodUrl = 'https://cdn.walkme.com/users/d2a25d237e044f35a33d831d38432af7/walkme_d2a25d237e044f35a33d831d38432af7_https.js';

const walkMeUrls: Record<Env, string> = {
  local: nonProdUrl,
  dev: nonProdUrl,
  qa: nonProdUrl,
  stage: nonProdUrl,
  prod: prodUrl,
};

export const attachWalkMeScript = (): void => {
  const walkMe = document.createElement('script');
  walkMe.type = 'text/javascript';
  walkMe.async = true;
  walkMe.src = chooseConfig(walkMeUrls);
  const headElement = document.getElementsByTagName('head')[0];
  if (headElement) {
    headElement.insertBefore(walkMe, headElement.firstChild);
    (window as any)._walkmeConfig = { smartLoad: true };
  }
};
