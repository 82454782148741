import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Button from '../../components/Button/Button';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import { ResourcePermission, RoleDefinition } from '../../types/users-types';
import { runInAction } from 'mobx';

interface RoleDefinitionRendererProps {
  row: RoleDefinition;
}

interface PermissionsRendererProps {
  row: ResourcePermission;
  index: number;
  tableId: string;
}

const RoleDefinitionRenderer = observer((props: RoleDefinitionRendererProps) => {
  const { row } = props;
  const store = useStore().rolesManagementStore;
  return (
    <Button className="cancel-editing" type='link' size='small' onClick={() => store.showRolePermissions(row)}>{row.name}</Button>
  );
});

export const PermissionsRenderer = observer((props: PermissionsRendererProps) => {
  const { row, index, tableId } = props;
  const store = useStore().roleManagementStore;

  const changeIndices = (newIndices: number[]): void => {
    runInAction(() => {
      store.edited = true;
      row.permissions.forEach((p, i) => {
        p.checked = newIndices.includes(i);
      });
    });
  };

  return (
    <CheckboxGroup
      id={`${tableId}_permissions-group__${index}`}
      type="horizontal"
      small
      values={row.permissions}
      displayField="id"
      onChange={indices => changeIndices(indices)}
      checkedIndices={row.permissions.map((p, i) => p.checked ? i : -1).filter(i => i > -1)}
    />
  );
});

export const roleDefinitionRenderer = (row: RoleDefinition) => <RoleDefinitionRenderer row={row} />;

export const permissionsRenderer = (row: ResourcePermission, index: number, tableId: string) => <PermissionsRenderer row={row} index={index} tableId={tableId}/>;
