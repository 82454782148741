import React, { CSSProperties } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon, { SpotIconPath } from '../SpotIcon/SpotIcon';
import './Button.scss';
import Spinner from '../Spinner/Spinner';

const BUTTON_TYPE = {
  primary: 'spot-button--primary',
  secondary: 'spot-button--secondary',
  link: 'spot-button--link',
};

const BUTTON_SIZE = {
  small: 'spot-button--small',
  large: 'spot-button--large',
  sales: 'spot-button--sales',
};

 interface ButtonProps extends CustomElementProps {
  type?: keyof typeof BUTTON_TYPE;
  size?: keyof typeof BUTTON_SIZE;
  onClick?: (e: any) => void;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  icon?: SpotIconPath;
  title?: string;
  cypressData?: string;
}

const Button = (props: ButtonProps) => {
  const { id, size, onClick, type, children, className, disabled, loading, style, icon, active, ariaLabel, title, role, cypressData } = props;
  let spinnerSize = size === 'small' ? 20 : size === 'large' ? 32 : 24;
  if (type === 'link') spinnerSize *= 0.7;

  const content = icon ? (
    <>
      <SpotIcon className='spot-button__icon spot-button__icon--left' path={icon} title={title}/>
      <span className='spot-button__text'>{children}</span>
    </>
  ) : children;

  const computedClass = [
    'spot-button',
    type && BUTTON_TYPE[type],
    size && BUTTON_SIZE[size],
    active && 'spot-button--active',
    icon && 'spot-button--with-icon',
    className,
  ].filter(p => p).join(' ');


  return (
    <button
      id={id}
      style={style}
      role={role || 'button'}
      title={title}
      aria-label={ariaLabel || icon}
      className={computedClass}
      disabled={disabled || loading}
      onClick={(e: any) => onClick && onClick(e)}
      data-cy={cypressData}
    >
      {loading ? (
        <>
          <span className="hidden-content">{content}</span>
          <Spinner className="button-spinner" size={spinnerSize} color={type === 'primary' ? 'white' : '#00a7b5' } />
        </>
      ) : content}
    </button>
  );
};

export default Button;
