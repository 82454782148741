import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarWithOffset.scss';
import { CustomElementProps } from '../../types/react-props-types';
import dayjs from 'dayjs';
import EventIntervalsHandler, { EventInterval } from '../../utils/event-intervals-handler';
import { localUtcOffset } from '../../utils/date-utils';

interface CalendarProps extends CustomElementProps {
  type: 'Date' | 'Time'
  value: number;
  onChange: (timestamp: number) => void;
  eventIntervalsHandler?: EventIntervalsHandler;
  utcOffset: number,
  minDate?: Date;
  maxDate?: Date;
  cypressData?: string;
}

const CalendarWithOffset = (props: CalendarProps) => {
  const { id, type, className, value, onChange, eventIntervalsHandler, utcOffset, minDate, maxDate, cypressData } = props;
  const [diffOffset, setDiffOffset] = useState<number>(0);
  const isDatePicker = type === 'Date';

  useEffect(() => {
    setDiffOffset(localUtcOffset(new Date()) - utcOffset);
  }, [utcOffset]);

  const toDiffOffset = (date: Date): number => dayjs(date).add(diffOffset, 'hours').valueOf();

  const fromDiffOffset = (timestamp: number): Date => dayjs(timestamp).add(-diffOffset, 'hours').toDate();

  const startOfDay = (timestamp: number): number => dayjs(timestamp).utcOffset(utcOffset).startOf('day').valueOf();

  const filterDate = (timestamp: number): boolean => {
    const dayStart = dayjs(timestamp).utcOffset(utcOffset).startOf('day');
    const fullDay: EventInterval = {
      start: dayStart.valueOf(),
      end: dayStart.add(1, 'day').add(-1, 'second').valueOf(),
    };
    return !eventIntervalsHandler || !eventIntervalsHandler.inSameEventInterval(fullDay);
  };

  const filterTime = (timestamp: number): boolean => {
    return !eventIntervalsHandler || !eventIntervalsHandler.inEventIntervals(timestamp);
  };

  const handleDateChange = (timestamp: number) => {
    if (!eventIntervalsHandler) {
      onChange(timestamp);
    } else {
      let nextAvailable = eventIntervalsHandler.nextAvailable(timestamp);
      if (nextAvailable !== false && startOfDay(timestamp) !== startOfDay(nextAvailable)) nextAvailable = false;
      if (nextAvailable !== false) {
        onChange(nextAvailable);
      } else {
        const prevAvailable = eventIntervalsHandler.prevAvailable(timestamp);
        if (prevAvailable) onChange(prevAvailable);
      }
    }
  };

  return (
    <div className={`calendar-with-offset ${className || ''}`} data-cy={cypressData}>
      <DatePicker
        id={id}
        selected={fromDiffOffset(value)}
        onChange={date => handleDateChange(toDiffOffset(date || new Date()))}
        filterDate={date => filterDate(toDiffOffset(date))}
        filterTime={date => filterTime(toDiffOffset(date))}
        minDate={minDate}
        maxDate={maxDate}
        inline={isDatePicker}
        showTimeSelect={!isDatePicker}
        showTimeSelectOnly={!isDatePicker}
        timeIntervals={30}
        dateFormat="h:mm aa"
        formatWeekDay={date => dayjs(date).format('dd').substring(0, 1)}
        disabledKeyboardNavigation={true}

      />
    </div>
  );
};

export default CalendarWithOffset;
