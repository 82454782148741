import { isDefined } from './object-utils';

export const getPathWithQuery = (path: string, queryParams?: Record<string, string | string[] | number | boolean | null | undefined>): string => {
  if (!queryParams) return path;
  const array = Object.keys(queryParams)
    .filter(key => isDefined(queryParams[key]))
    .map(key => {
      let value = queryParams[key];
      if (value === undefined || value === null) {
        value = '';
      }
      if (Array.isArray(value)) {
        value = value.join(',');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    });
  return array.length === 0 ? path : `${path}?${array.join('&')}`;
};
