import React, { CSSProperties, useEffect, useRef } from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import './TextBox.scss';

interface TextBoxProps extends CustomElementProps {
  error?: boolean | string;
  disabled?: boolean;
  readonly?: boolean;
  value?: string;
  label?: string;
  inCell?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  password?: boolean;
  focus?: boolean;
  inline?: boolean;
  maxLength?: number;
  cypressData?: string;
}

const TextBox = (props: TextBoxProps) => {
  const { id, placeholder, value, disabled, error, readonly, onChange, className, style, password, label, focus, ariaLabel, inline, cypressData, maxLength, inCell } = props;
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (focus && ref.current) {
      ref.current.focus();
    }
  }, [focus]);

  const displayStyle: CSSProperties = { display: inline ? 'inline-block' : 'block' };

  return (
    <div
      className={`spot-form__field-group text-box_wrapper ${inCell ? 'in-cell' : ''} ${error ? 'spot-form--error' : ''} ${(error ? className : '') || ''}`}
      style={displayStyle}
    >
      {label && <label className="spot-form__label" htmlFor={id}>{label}</label>}
      <input
        id={id}
        role="input"
        aria-label={ariaLabel}
        className={`spot-form__input ${(!error ? className : '') || ''}`}
        ref={ref}
        type={password ? 'password' : 'text'}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        readOnly={readonly}
        disabled={disabled}
        style={{...style, ...displayStyle}}
        onChange={e => onChange && onChange(e.target.value)}
        data-cy={cypressData}
      />
      {error && error !== true && (
        <span className="spot-form__field-error" role="alert">
          <span className="spot-form__field-error-text" role="error-text" aria-label={`${ariaLabel}_error`}>{error}</span>
        </span>
      )}
    </div>
  );
};

export default TextBox;
