import React from 'react';
import './Footer.scss';
import dayjs from 'dayjs';

const Footer = (): any => (
  <div className='footer'>
    <div className='footer-logo'>
      <img src='/images/IDEXX_Logo_White.svg' />
    </div>
    <div id='copyright' className='copyright'>&copy; {dayjs().year()} IDEXX Laboratories, Inc. All rights reserved.</div>
  </div>
);

export default Footer;
