import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/hooks';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import './PracticeUserManagement.scss';
import { isDefined } from '../../utils/object-utils';

const PracticeUserManagement = observer(() => {
  const navigate = useNavigate();
  const { practiceUserManagementStore: store, globalStore } = useStore();
  const { colDefs, users, loaders } = store;

  useEffect(() => {
    if (!store.sapId) {
      navigate('/');
      return;
    }
    void store.init();
  }, [navigate, store, store.sapId]);

  if (loaders.page) return <Spinner text="One moment while we retrieve your practice users"/>;

  return (
    <div className='practice-user-management-wrapper'>
      <div className='heading--level-1'>Manage Users for <span title={globalStore.selectedSap.name}>{globalStore.selectedSap.id}</span></div>
      <div className='spot-typography__text--tertiary'>
        Create and manage users, including role assignments, for your practice&apos;s MyCornerstone account. Only Practice Admin role users can manage portal users.<br/>
        For each portal user, use the same email address that is registered with their MyIDEXX account.
      </div>
      <Button
        className="add-more-btn"
        ariaLabel="add-more"
        type="link"
        size="small"
        icon="plus"
        disabled={loaders.user}
        onClick={() => store.addUser()}
      >
        Add Users
      </Button>
      {loaders.user && <Spinner size={24} />}
      <Grid
        id='practice-users-management-grid'
        columns={colDefs}
        data={users}
      />

      <div className="practice-user-management_actions">
        <Button className="back item-editors" type='link' size='small' onClick={() => store.back()}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' disabled={loaders.user || isDefined(store.currentIndex)} onClick={() => store.done()}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default PracticeUserManagement;
