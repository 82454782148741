import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon, { SpotIconPath } from '../SpotIcon/SpotIcon';

const PILL_TYPES_CLASSES = {
  primary: 'spot-pill--primary',
  secondary: 'spot-pill--secondary',
  positive: 'spot-pill--positive',
  negative: 'spot-pill--negative',
  warning: 'spot-pill--warning',
};

interface PillProps extends CustomElementProps {
  type: keyof typeof PILL_TYPES_CLASSES;
  outline?: boolean;
  small?: boolean;
  interactive?: boolean;
  title?: string;
  icon?: SpotIconPath;
  onClick?: () => void;
  cypressData?: string;
}

const Pill = (props: PillProps) => {
  const { id, interactive, outline, className, small, type, title, icon, children, onClick, cypressData } = props;
  const computedClass = [
    'spot-pill',
    className,
    type && PILL_TYPES_CLASSES[type],
    outline && 'spot-pill--outline',
    small && 'spot-pill--small',
    interactive && 'spot-pill--interactive',
  ].join(' ');

  const internal = <>
    {icon && <SpotIcon className="spot-pill__icon spot-pill__icon--left" path={icon} />}
    <span className="spot-pill__icon-label">{children}</span>
  </>;

  return interactive ? (
    <button id={id} className={computedClass} title={title} onClick={() => onClick && onClick()} data-cy={cypressData}>{internal}</button>
  ) : (
    <span id={id} className={computedClass} title={title} data-cy={cypressData}>{internal}</span>
  );
};

export default Pill;
