import React from 'react';
import Select, {components} from 'react-select';
import {CustomElementProps} from '../../types/react-props-types';
import Checkbox from '../Checkbox/Checkbox';

interface Props extends CustomElementProps {
  options: any[];
  isDisabled: boolean;
  onChange: (values: string[]) => void;
}

/* eslint-disable react/prop-types */
const InputOption = ({
                       isSelected,
                       children,
                       innerProps,
                       ...rest
                     }) => {
  return (
    // @ts-ignore
    <components.Option
      {...rest}
      isSelected={isSelected}
      innerProps={innerProps}
    >
      <Checkbox
        id="option-checkbox"
        checked={isSelected}>
        {children}
      </Checkbox>
    </components.Option>
  );
};

const CheckboxDropDown = (props: Props) => {
  const {options, isDisabled, onChange} = props;

  return (
    <>
      <Select
        isSearchable={false}
        isMulti
        isDisabled={isDisabled}
        placeholder="Select Event Type"
        closeMenuOnSelect={false}
        onChange={(options) => {
          onChange(options.map((opt: any) => opt.value));
        }}
        options={options}
        components={{
          Option: InputOption
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? "0 0 0 1px #00a7b5" : base.boxShadow,
            borderColor: (state.isFocused || state.menuIsOpen) ? "#00a7b5": base.borderColor,
            backgroundColor: state.isDisabled ? "#ffffff" : base.backgroundColor
          }),
          menu: (base) => ({
            ...base,
            zIndex: 1000
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: "#33bfcc",
            color: "white"
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: "white",
            fontSize: 13,
            fontWeight: "bold"
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#f4f6f7" : base.backgroundColor
          }),
        }}
      />
    </>
  );
};

export default CheckboxDropDown;
