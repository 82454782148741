import ApiStore from './ApiStore';
import ScheduleUpgradeStore from '../pages/ScheduleUpgrade/ScheduleUpgradeStore';
import CheckQualificationsStore from '../pages/CheckQualifications/CheckQualificationsStore';
import GlobalStore from './GlobalStore';
import DashboardStore from '../pages/Dashboard/DashboardStore';
import AbstractStore from './AbstractStore';
import ConfirmationStore from '../pages/Confirmation-Summary/ConfirmationStore';
import RolesManagementStore from '../pages/RolesManagement/RolesManagementStore';
import { makeObservable } from 'mobx';
import SideMenuStore from '../components/SideMenu/SideMenuStore';
import RoleManagementStore from '../pages/RolesManagement/RoleManagementStore';
import AuditStore from '../pages/Audit/AuditStore';
import UsersManagementStore from '../pages/UsersManagement/UsersManagementStore';
import PracticeUserManagementStore from '../pages/PracticeUserManagement/PracticeUserManagementStore';
import ServiceEventsStore from '../pages/ServiceEvents/ServiceEventsStore';
import VerificationCodeModalContentStore from '../pages/Dashboard/VerificationCodeModalContentStore';
import UpgradeManagementStore from '../pages/UpgradeManagement/UpgradeManagementStore';
import AcknowledgmentsStore from '../pages/Acknowledgments/AcknowledgmentsStore';

export default class RootStore {
  public readonly globalStore = new GlobalStore(this);
  public readonly apiStore = new ApiStore(this);
  public readonly dashboardStore = new DashboardStore(this);
  public readonly scheduleUpgradeStore = new ScheduleUpgradeStore(this);
  public readonly checkQualificationsStore = new CheckQualificationsStore(this);
  public readonly confirmationStore = new ConfirmationStore(this);
  public readonly rolesManagementStore = new RolesManagementStore(this);
  public readonly usersManagementStore = new UsersManagementStore(this);
  public readonly roleManagementStore = new RoleManagementStore(this);
  public readonly sideMenuStore = new SideMenuStore(this);
  public readonly auditStore = new AuditStore(this);
  public readonly practiceUserManagementStore = new PracticeUserManagementStore(this);
  public readonly serviceEventsStore = new ServiceEventsStore(this);
  public readonly verificationCodeModalContentStore = new VerificationCodeModalContentStore(this);
  public readonly upgradeManagementStore = new UpgradeManagementStore(this);
  public readonly acknowledgmentsStore = new AcknowledgmentsStore(this);


  constructor() {
    Object.keys(this).forEach(storeName => {
      const store = this[storeName];
      if (!(store instanceof AbstractStore)) throw new Error(`${storeName} is not an instance of AbstractStore subclass`);
      makeObservable(store);
    });
  }

  // no methods allowed in this class because they will be anyway unavailable in useStore() hook result
}
