import { action, computed, observable, runInAction } from 'mobx';
import type { SortingObservable } from '../../components/Grid/Grid';
import { GridColDef } from '../../components/Grid/Grid';
import AbstractStore from '../../store/AbstractStore';
import { sleep, sortByField } from '../../utils/object-utils';
import type { ResourcePermission, RoleDefinition } from '../../types/users-types';
import { permissionsRenderer } from './renderers';
import { ROLES_PATH } from '../../store/constants';

interface Loaders {
  role?: boolean;
  page?: boolean;
}

export default class RoleManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable sorting: SortingObservable<ResourcePermission> = {};

  @observable resourcePermissions: ResourcePermission[] = [];

  @observable roleName = '';

  @observable edited = false;

  @computed
  public get resourcePermissionsFiltered(): ResourcePermission[] {
    let filtered = this.resourcePermissions || [];
    if (this.sorting.field) {
      filtered = sortByField(filtered, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        filtered = filtered.reverse();
      }
    }
    return filtered;
  }

  public get colDefs(): GridColDef<ResourcePermission>[] {
    return [
      { headerName: 'Resource', field: 'resourceId', sortable: true },
      { headerName: 'Description', field: 'description', sortable: true },
      { headerName: 'Type', field: 'type', sortable: true },
      { headerName: 'Permissions', field: 'permissions', renderer: permissionsRenderer },
    ];
  }

  @action
  public async init(): Promise<void> {
    if (!this.roleName) {
      await sleep(10);
      this.navigate('/');
      return;
    }
    runInAction(() => this.loaders.page = true);
    if (this.resourcePermissions.length === 0) {
      const resourcesAndPermissions = await this.root.apiStore.findAllResourcesAndPermissions();
      runInAction(() => {
        this.resourcePermissions = resourcesAndPermissions.resources.map(r => ({
          resourceId: r.resourceId,
          type: r.type,
          description: r.description,
          permissions: sortByField(resourcesAndPermissions.permissions.filter(p => p.type === r.type), 'id'),
        }));
      });
    }
    const roleDefinition = await this.root.apiStore.findRoleByName(this.roleName);
    this.checkUsedResourcePermissions(roleDefinition);
    runInAction(() => {
      this.loaders.page = false;
      this.edited = false;
    });
  }

  @action
  public async updateRole(): Promise<void> {
    runInAction(() => this.loaders.role = true);
    const payload = {
      action: 'UPDATE_ROLE_PERMISSIONS',
      roleName: this.roleName,
      resourcePermissions: this.resourcePermissions.map(rp => ({
        ...rp,
        permissions: rp.permissions.filter(p => p.checked).map(p => ({ ...p, checked: undefined }))
      })).filter(rp => rp.permissions.length > 0),
    };
    await this.root.apiStore.backendPost(ROLES_PATH, payload);
    runInAction(() => this.loaders.role = false);
    this.navigate('/admin/roles-management');
  }

  @action
  public close(): void {
    this.sorting = {};
    this.roleName = '';
    this.resourcePermissions.forEach(rp => rp.permissions.forEach(p => p.checked = false));
  }

  @action
  private checkUsedResourcePermissions(roleDefinition: RoleDefinition): void {
    this.resourcePermissions.forEach(rp => {
      const permissions = roleDefinition?.resourcePermissions?.find(rp1 => rp1.resourceId === rp.resourceId)?.permissions || [];
      rp.permissions.forEach(p => {
        p.checked = permissions.some(p1 => p1.id === p.id);
      });
    });
  }
}
