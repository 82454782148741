import RootStore from './RootStore';
import { computed } from 'mobx';
import { AUDIT_PATH } from './constants';
import type { NavigateFunction } from 'react-router';

export default abstract class AbstractStore {
  public readonly root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
  }

  @computed
  public get sapId(): string {
    return this.root.globalStore.selectedSap.id;
  }

  @computed
  public get sapIdChanged(): boolean {
    return this.root.globalStore._sapIdChanged;
  }

  public set sapIdChanged(changed: boolean) {
    if (!this.root.globalStore._sapIdChanged && changed) {
      this.onChangeSapId();
    }
    this.root.globalStore._sapIdChanged = changed;
  }

  public get navigate(): NavigateFunction {
    return this.root.globalStore.navigate;
  }

  protected async audit(auditEventType: string, auditEvent: string): Promise<any> {
    this.root.apiStore.auditRequestPromise = this.root.apiStore.auditRequestPromise.then(() => this.root.apiStore.backendPost(AUDIT_PATH, {
      sapId: this.sapId,
      auditEvent,
      auditEventType,
    })).catch(() => {});
    await this.root.apiStore.auditRequestPromise;
  }

  public onChangeSapId(): void {}

  public signOut(): void | Promise<void> {}
}
