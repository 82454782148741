import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import { PracticeConfig } from '../../types/practice-types';
import type { GridColDef, SortingObservable } from '../../components/Grid/Grid';
import {
  actionsRenderer,
  contactsRenderer,
  dateRenderer,
  MessageCancelUpgrade,
  MessageCancelUpgradeCommunicationError,
  MessageCancelUpgradeError,
  reportRenderer,
  statusRenderer,
} from './renderers';
import { GLOBALS_PATH, PRACTICE_PATH } from '../../store/constants';
import React from 'react';
import { isDefined, sortByField } from '../../utils/object-utils';
import dayjs from 'dayjs';
import { regexToSearchWithWildcards } from '../../utils/search-utils';

interface Loaders {
  page?: boolean;
  refresh?: boolean;
  maxUpgrades?: boolean;
}

export default class UpgradeManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable
  public items: PracticeConfig[] = [];

  @observable
  public cancellingItemIndex: number | undefined = undefined;

  @observable
  public maxUpgrades = '0';

  @observable
  public maxUpgradesEditMode = false;

  @observable
  public maxUpgradesCurrent= '0';

  @observable
  public maxUpgradesError= '';

  @observable
  public sorting: SortingObservable<PracticeConfig> = {};

  @observable
  public futureOnly = false;

  @observable
  public searchText = '';

  @computed
  public get colDefs(): GridColDef<PracticeConfig>[] {
    return [
      { headerName: 'Upgrade Status', field: 'scheduleUpgradeStatus', renderer: statusRenderer, sortable: true },
      { headerName: 'SAP ID', field: 'sapId', sortable: true },
      { headerName: 'Practice Name', field: 'practiceName', sortable: true },
      { headerName: 'Upgrade Date And Time', field: 'scheduleUpgradeTimestamp', renderer: row => dateRenderer(row.scheduleUpgradeTimestamp), sortable: true },
      { headerName: 'Machine Count', field: '', renderer: row => row.successAgentIds?.length || 0, sortable: false },
      { headerName: 'Practice Contacts', field: 'contacts', renderer: contactsRenderer, sortable: false },
      { headerName: 'Last Updated By', field: 'scheduledBy', sortable: true },
      { headerName: 'Last Updated On', field: 'scheduleTimestamp', renderer: row => dateRenderer(row.scheduleTimestamp), sortable: true },
      { headerName: 'Summary Report', field: 'pdfUrl', renderer: reportRenderer, sortable: false },
      { headerName: 'Actions', field: '', renderer: actionsRenderer, sortable: false },
    ];
  }

  @computed
  public get itemsFiltered(): PracticeConfig[] {
    let filtered = this.items;
    if (this.searchText !== '') {
      const regex = regexToSearchWithWildcards(this.searchText);
      filtered = this.items.filter(i => (
        regex.test(i.sapId || '') || regex.test(i.practiceName || '') || regex.test(i.scheduledBy || '')
          || i.contacts?.some(c => regex.test(c.name))
      ));
    }
    if (this.futureOnly) {
      const now = dayjs().valueOf();
      filtered = filtered.filter(i => isDefined(i.scheduleUpgradeTimestamp) && i.scheduleUpgradeTimestamp > now);
    }
    if (this.sorting.field) {
      filtered = sortByField(filtered, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        filtered = filtered.reverse();
      }
    }
    return filtered;
  }

  @computed
  public get cancelling() {
    return this.cancellingItemIndex !== undefined;
  }

  @action
  public async init(): Promise<void> {
    runInAction(() => {
      this.loaders = {
        page: true,
      };
      this.sorting = {};
      this.futureOnly = false;
      this.searchText = '';
      this.cancellingItemIndex = undefined;
      this.maxUpgradesEditMode = false;
    });
    const items = await this.root.apiStore.getPracticesToUpgrade();
    const maxUpdatesPerDay = await this.root.apiStore.getGlobalValue<number>('UPGRADES_PER_DAY_MAXIMUM_NUMBER');
    runInAction(() => {
      this.items = items;
      this.maxUpgrades = String(maxUpdatesPerDay || 0);
      this.loaders.page = false;
    });
  }

  @action
  public async refresh(): Promise<void> {
    runInAction(() => {
      this.loaders.refresh = true;
      this.items = [];
    });
    const items = await this.root.apiStore.getPracticesToUpgrade();
    runInAction(() => {
      this.items = items;
      this.loaders.refresh = false;
    });
  }

  @action
  public promptCancelUpgrade(index: number): void {
    this.root.globalStore.showOkCancelPopup(
      'Cancel Upgrade',
      React.createElement(MessageCancelUpgrade),
      'Confirm',
      'Cancel',
      () => this.cancelUpgrade(index),
      undefined,
      false,
      false,
    );
  }

  @action
  public startEditingMaxUpgrades() {
    this.maxUpgradesCurrent = this.maxUpgrades;
    this.maxUpgradesEditMode = true;
    this.maxUpgradesError = '';
  }

  @action
  public cancelEditingMaxUpgrades() {
    this.maxUpgradesEditMode = false;
    this.maxUpgradesError = '';
  }

  @action
  public changeMaxUpgrades(value: string) {
    if (/^\d*$/.test(value) && value.length <= 7) this.maxUpgradesCurrent = value;
  }

  @action
  public async updateMaxUpgrades(): Promise<void> {
    const maxUpgradesNumber = parseInt(this.maxUpgradesCurrent);
    if (maxUpgradesNumber === 0) {
      runInAction(() => this.maxUpgradesError = 'Cannot be 0');
    } else if (maxUpgradesNumber === parseInt(this.maxUpgrades)) {
      runInAction(() => this.maxUpgradesEditMode = false);
    } else {
      runInAction(() => this.loaders.maxUpgrades = true);
      await this.root.apiStore.backendPost(GLOBALS_PATH, {
        key: 'UPGRADES_PER_DAY_MAXIMUM_NUMBER',
        value: maxUpgradesNumber,
      });
      runInAction(() => {
        this.maxUpgrades = this.maxUpgradesCurrent;
        this.maxUpgradesEditMode = false;
        this.loaders.maxUpgrades = false;
      });
    }
  }

  @action
  private async cancelUpgrade(index: number): Promise<void> {
    runInAction(() => {
      this.root.globalStore.globalModalProps.show = false;
      this.cancellingItemIndex = index;
    });
    void this.audit('UPGRADE_MANAGEMENT', 'UPGRADE_MANAGEMENT_PAGE_CANCEL_UPGRADE');
    try {
      const practice = await this.root.apiStore.backendPost(PRACTICE_PATH, {
        action: 'CANCEL_UPGRADE',
        sapId: this.itemsFiltered[index].sapId,
      });
      runInAction(() => {
        const itemIndex = this.items.findIndex(i => i.sapId === this.itemsFiltered[index].sapId);
        if (itemIndex > -1) {
          this.items[itemIndex] = practice;
        }
      });
    } catch (err: any) {
      this.root.globalStore.showOkPopup(
        'Connection error',
        'Unable to connect to Kaseya, please try again later',
      );
    }
    if (this.itemsFiltered[index].scheduleError === 'Kaseya') {
      this.root.globalStore.showOkPopup(
        'Error Cancelling',
        React.createElement(MessageCancelUpgradeError),
      );
    }
    if (this.itemsFiltered[index].scheduleError === 'Communication') {
      this.root.globalStore.showOkPopup(
        'Communication error',
        React.createElement(MessageCancelUpgradeCommunicationError),
      );
    }
    runInAction(() => this.cancellingItemIndex = undefined);
  }
}
