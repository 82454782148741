import React from 'react';
import SpotIcon from '../SpotIcon/SpotIcon';
import './QualificationIcon.scss';

interface QualificationIconProps {
  pass?: boolean;
}

const QualificationIcon = (props: QualificationIconProps) => {
  const { pass } = props;
  return <SpotIcon className={`qualification-icon ${pass ? 'pass' : ''}`} path={pass ? 'checkmark' : 'cancel'} />;
};

export default QualificationIcon;
