import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import { GridColDef } from '../../components/Grid/Grid';
import { RoleApi } from '../../types/users-types';
import type { UserForEditing } from './renderers';
import { actionsRenderer, rowCellRenderer } from './renderers';
import { arraysHaveSameElements, isEmailAddress } from '../../utils/object-utils';
import { USERS_PATH } from '../../store/constants';

const emptyUser = (): UserForEditing => ({ firstName: '', lastName: '', phone: '', email: '', roles: [] });
const isEmptyUser = (user: UserForEditing): boolean => Object.keys(user).filter(key => key !== 'roles').every(key => !user[key]);
const equalUser = (user1: UserForEditing, user2: UserForEditing): boolean => Object.keys(user1).filter(key => key !== 'roles').every(key => user1[key] === user2[key]);

const PRACTICE_ADMIN_ROLE = 'MyCSSapAdminRole';
const PRACTICE_GUEST_ROLE = 'MyCSSapGuestRole';
const MSG_PRACTICE_ADMIN_ROLE_DELETED = 'At least one Practice Admin user is required. Please add a new admin user or update an existing user role before changing this user\'s role.';
const MSG_USER_WITH_PRACTICE_ADMIN_ROLE_DELETED = 'At least one Practice Admin user is required. Please add a new admin user or update an existing user role before deleting this user.';

interface Loaders {
  page?: boolean;
  user?: boolean;
}

const MODAL_ID = 'practice-user-management-modal';

export default class PracticeUserManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable users: UserForEditing[] = [];

  @observable currentIndex: number | undefined = undefined;

  @observable addMode = false;

  @observable currentUser: UserForEditing = emptyUser();

  @observable roles: RoleApi[] = [];

  @observable currentRoleIndices: number[] = [];

  @observable userError: UserForEditing = emptyUser();

  private practiceAdminRoleIndex = -1;

  private practiceGuestRoleIndex = -1;

  @computed
  public get colDefs(): GridColDef<UserForEditing>[] {
    return [
      { headerName: 'First Name', field: 'firstName', renderer: rowCellRenderer('firstName') },
      { headerName: 'Last Name', field: 'lastName', renderer: rowCellRenderer('lastName') },
      { headerName: 'Email', field: 'email', renderer: rowCellRenderer('email') },
      { headerName: 'Phone (mobile)', field: 'phone', renderer: rowCellRenderer('phone') },
      { headerName: 'Roles', field: 'roles', renderer: rowCellRenderer('roles') },
      { headerName: 'Actions', field: 'actions', renderer: actionsRenderer },
    ];
  }

  public async init(): Promise<void> {
    runInAction(() => this.loaders.page = true);
    const users = await this.root.apiStore.findUsersForSap(this.sapId);
    let roles: RoleApi[] = [];
    if (this.roles.length === 0) {
      roles = await this.root.apiStore.findRolesByGlobalFlag(false);
    }
    runInAction(() => {
      this.users = users.map(u => {
        const { firstName, lastName, email, phone } = u;
        return { firstName, lastName, email, phone, roles: u.sapRoles?.find(sr => sr.sapId === this.sapId)?.roles || [] };
      });
      if (roles.length > 0) {
        this.roles = roles;
        this.practiceAdminRoleIndex = roles.findIndex(r => r.name === PRACTICE_ADMIN_ROLE);
        this.practiceGuestRoleIndex = roles.findIndex(r => r.name === PRACTICE_GUEST_ROLE);
      }
      this.loaders.page = false;
      this.currentIndex = undefined;
    });
  }

  @action
  public addUser(): void {
    this.addMode = true;
    this.users.push(emptyUser());
    this.currentUser = emptyUser();
    this.userError = emptyUser();
    this.currentIndex = this.users.length - 1;
    this.currentRoleIndices = [this.roles.length > 1 ? 1 : 0]; // choosing Practice User role as default
    void this.audit('USER_MANAGEMENT', 'PRACTICE_USER_MANAGEMENT_PAGE_ADD_USER');
  }

  @action
  public editUser(index: number): void {
    if (this.loaders.user) return;
    this.addMode = false;
    this.currentUser = { ...this.users[index] };
    this.userError = emptyUser();
    this.currentIndex = index;
    this.currentRoleIndices = this.currentUser.roles.map(r => this.roles.findIndex(ar => ar.name === r.name)).filter(i => i >= 0);
    void this.audit('USER_MANAGEMENT', 'PRACTICE_USER_MANAGEMENT_PAGE_EDIT_USER');
  }

  @action
  public deleteUser(index: number): void {
    if (this.currentUserHasPracticeAdminRole(index) && !this.isAnotherUserWithPracticeAdminRoleExists(index)) {
      this.showDeletePracticeAdminModal('Delete Practice Admin role', MSG_USER_WITH_PRACTICE_ADMIN_ROLE_DELETED);
    } else {
      this.showDeleteUserModal(index);
    }
  }

  @action
  public showDeletePracticeAdminModal(header: string, msg: string): void {
    const closeModal = (): void => runInAction((): void => {
      this.root.globalStore.globalModalProps.show = false;
    });
    this.root.globalStore.globalModalProps = {
      id: MODAL_ID,
      show: true,
      okCaption: 'OK',
      header: header,
      children: msg,
      className: 'item-editors',
      onOk: closeModal,
      onExit: closeModal,
    };
  }

  @action
  public showDeleteUserModal(index: number): void {
    this.root.globalStore.showOkCancelPopup(
      'Confirm user deletion',
      'Are you sure you want to delete this user?',
      'OK',
      'Cancel',
      () => this.doDeleteUser(index),
    );
  }

  @action
  public async cancelEditingUser(): Promise<void> {
    if (this.currentIndex === undefined) return;
    if (isEmptyUser(this.users[this.currentIndex])) {
      runInAction(() => this.users = this.users.filter((_u, uIndex) => uIndex !== this.currentIndex));
    } else {
      runInAction(() => this.currentIndex = undefined);
    }
  }

  @action
  public async validateAndUpdateUser(): Promise<void> {
    if (this.currentIndex === undefined) return;
    // to prevent multiple calls of this method
    const currentIndex = this.currentIndex;
    runInAction(() => {
      this.currentIndex = undefined;
      const { firstName, lastName, phone, email } = this.currentUser;
      this.userError = emptyUser();
      if (!firstName) this.userError.firstName = 'First name should be set';
      if (!lastName) this.userError.lastName = 'Last name should be set';
      if (!email)  this.userError.email = 'Email should be set';
      if (email) {
        if (!isEmailAddress(email)) {
          this.userError.email = 'Invalid email format';
        } else if (this.isEmailAddressAlreadyExists(email, currentIndex)) {
          this.userError.email = 'A user with this email already exists';
        }
      }
      if (phone && !/^\d{10}$/.test(phone)) this.userError.phone = 'Invalid phone format';
    });
    if (!isEmptyUser(this.userError)) {
      runInAction(() => this.currentIndex = currentIndex);
    } else {
      if (!equalUser(this.currentUser, this.users[currentIndex]) || !arraysHaveSameElements(this.getInitialUserRoleIndices(currentIndex), this.currentRoleIndices)) {
        await this.updateUser(currentIndex);
      }
      runInAction(() => this.currentIndex = undefined);
    }
  }

  @action.bound
  public changeRoleIndices(indices: number[], addedIndex: number | undefined, removedIndex: number | undefined): void {
    if (this.currentIndex === undefined) return;
    if (addedIndex === this.practiceGuestRoleIndex) {
      indices = [addedIndex];
      removedIndex = this.practiceAdminRoleIndex;
    } else if (addedIndex !== undefined) {
      indices = indices.filter(i => i !== this.practiceGuestRoleIndex);
    }
    if (removedIndex === this.practiceAdminRoleIndex && !this.isAnotherUserWithPracticeAdminRoleExists(this.currentIndex)) {
      indices.push(this.practiceAdminRoleIndex);
      indices = indices.filter(i => i !== this.practiceGuestRoleIndex);
      this.showDeletePracticeAdminModal('Change Practice Admin role', MSG_PRACTICE_ADMIN_ROLE_DELETED);
    }
    if (indices.length > 0) {
      this.currentRoleIndices = indices;
    }
  }

  @action
  public back(): void {
    void this.audit('USER_MANAGEMENT', 'PRACTICE_USER_MANAGEMENT_PAGE_BACK');
    this.navigate(-1);

  }

  @action
  public async done(): Promise<void> {
    void this.audit('USER_MANAGEMENT', 'PRACTICE_USER_MANAGEMENT_PAGE_DONE');
    this.navigate('/');
  }

  // todo this won't work for a pagination if it is introduced, in that case we should do a server call
  private isAnotherUserWithPracticeAdminRoleExists(excludeUserIndex: number): boolean {
    return this.users.filter((_u, uIndex) => uIndex !== excludeUserIndex).some(u => u.roles.some(r => r.name === PRACTICE_ADMIN_ROLE));
  }

  private currentUserHasPracticeAdminRole(index: number): boolean {
    return this.users[index].roles.some(r => r.name === PRACTICE_ADMIN_ROLE);
  }

  private getInitialUserRoleIndices(currentUserIndex: number): number[] {
    const initialUser = this.users[currentUserIndex];
    return initialUser.roles.map(ur => this.roles.findIndex(r => r.name === ur.name)).filter(i => i > -1);
  }

  private isEmailAddressAlreadyExists(email: string, ignoreIndex: number): boolean {
    return this.users.some((u, index) => ignoreIndex !== index && u.email.toUpperCase() === email.toUpperCase());
  }

  @action
  private async updateUser(currentIndex: number): Promise<void> {
    const editedUser: UserForEditing = {
      ...this.currentUser,
      email: this.currentUser.email.toLowerCase(),
      roles: this.currentRoleIndices.map(i => this.roles[i]),
    };
    runInAction(() => {
      this.loaders.user = true;
      if (this.addMode) {
        this.users.pop();
      }
    });
    try {
      const action = this.addMode ? 'CREATE_FOR_SAP' : 'UPDATE_FOR_SAP';
      void this.audit('USER_MANAGEMENT', `PRACTICE_USER_MANAGEMENT_PAGE_${action}_USER`);
      await this.root.apiStore.backendPost(USERS_PATH, {
        action,
        sapId: this.sapId,
        ...editedUser,
        roleNames: editedUser.roles.map(r => r.name),
      });
      runInAction(() => {
        this.addMode ? this.users.push(editedUser) : this.users[currentIndex] = editedUser;
      });
    } catch {
      // ignore
    }
    runInAction(() => this.loaders.user = false);
  }

  private async doDeleteUser(index: number): Promise<void> {
    if (this.loaders.user) return;
    runInAction(() => this.loaders.user = true);
    try {
      void this.audit('USER_MANAGEMENT', 'PRACTICE_USER_MANAGEMENT_PAGE_DELETE_USER');
      await this.root.apiStore.backendPost(USERS_PATH, {
        action: 'DELETE_FOR_SAP',
        sapId: this.sapId,
        ...this.users[index],
        roleNames: [],
      });
      runInAction(() => this.users = this.users.filter((_u, uIndex) => uIndex !== index));
    } catch {
      // ignore
    }
    runInAction(() => {
      this.currentIndex = undefined;
      this.loaders.user = false;
    });
  }
}
